<template>
  <div id="main">
    <!-- SDK下载 -->
    <div class="sdk">
      <div class="title">
        <img src="../images/paas/sjx2.png" alt="" />
        <span>SDK下载</span>
      </div>
      <ul>
        <li v-for="v in sdklist" :key="v.top">
          <img class="icon" :src="v.icon" alt="" />
          <div class="txt">
            <div class="top">{{ v.top }}</div>
            <div>{{ v.versions }}</div>
            <div>{{ v.type }}</div>
          </div>
          <div class="btn" @click="download(v)"></div>
        </li>
      </ul>
    </div>
    <!-- Demo安装包下载 -->
    <div class="demo">
      <div class="title">
        <img src="../images/paas/sjx2.png" alt="" />
        <span>Demo安装包下载</span>
      </div>
      <ul>
        <li v-for="v in demolist" :key="v.top" @click="clickDemoItem(v)">
          <img class="icon" :src="v.icon" alt="" />
          <div class="txt">
            <div class="top">{{ v.top }}</div>
            <div>{{ v.versions }}</div>
            <div>{{ v.type }}</div>
          </div>
          <div v-if="!v.isWebDemo && !v.isH5" class="btn" @click="download(v, 'xcx')"></div>
        </li>
      </ul>
    </div>
    <!-- Demo示例代码下载 -->
    <div class="code">
      <div class="title">
        <img src="../images/paas/sjx2.png" alt="" />
        <span>Demo示例代码下载</span>
      </div>
      <ul>
        <li v-for="v in codelist" :key="v.top" @click="clickDemoItem(v)">
          <img class="icon" :src="v.icon" alt="" />
          <div class="txt">
            <div class="top">{{ v.top }}</div>
            <div>{{ v.versions }}</div>
            <div>{{ v.type }}</div>
          </div>
          <div v-if="!v.isWebDemo" class="btn" @click="download(v)"></div>
        </li>
      </ul>
    </div>
    <!-- API文档下载 -->
    <div class="api">
      <div class="title">
        <img src="../images/paas/sjx2.png" alt="" />
        <span>SDK API文档下载</span>
      </div>
      <ul>
        <li v-for="v in apiList" :key="v.top">
          <img class="icon" :src="v.icon" alt="" />
          <div class="txt">
            <div class="top">{{ v.top }}</div>
            <div>{{ v.versions }}</div>
            <div>{{ v.type }}</div>
          </div>
          <div class="btn" @click="download(v)"></div>
        </li>
      </ul>
    </div>

    <div class="serverList">
      <div class="title">
        <img src="../images/paas/sjx2.png" alt="" />
        <span>服务器API文档下载</span>
      </div>
      <ul>
        <li v-for="v in serverList" :key="v.top">
          <img class="icon" :src="v.icon" alt="" />
          <div class="txt">
            <div class="top">{{ v.top }}</div>
            <div>{{ v.versions }}</div>
            <div>{{ v.type }}</div>
          </div>
          <div class="btn" @click="download(v)"></div>
        </li>
      </ul>
    </div>

    <!-- 小程序下载或微信浏览器入会图片及遮罩层 -->
    <div class="loadBg" v-if="showImg" @click="closeBox">
      <div class="imgBox">
        <div class="imgTitle">
          <span>{{ isWXH5 ? '微信浏览器' : '小程序'}}</span>
          <img src="../images/paas/close.png" @click="closeBox" alt="" />
        </div>
        <img v-if="isWXH5" src="../images/paas/wx_web_login.png" alt="" />
        <img v-else src="../images/paas/xcxlogin.png" alt="" />
        <div class="imgfoot">{{ isWXH5 ? '微信扫码即可入会体验' : '请扫码后点击“体验会议室”入会体验'}}</div>
        <div class="closeBtn" @click="closeBox">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // sdk下载
      sdklist: [
        {
          icon: require('../images/paas/windows.png'),
          top: 'Windows',
          versions: '版本3.37.10.51',
          type: 'C++',
          url: 'windows/sdk/hstsdk_3.37.10.51.7z',
        },
        {
          icon: require('../images/paas/android-icon.png'),
          top: 'Android',
          versions: '版本1.0.15',
          type: 'Link',
          url: 'android/sdk/AndroidPhoneSdk-3.36.10.5.aar',
        },
        {
          icon: require('../images/paas/mac.png'),
          top: 'iOS',
          versions: '版本3.36.10.10',
          type: 'ObjectiveC',
          url: 'ios/sdk/IosSdk.framework.zip',
        },
        {
          icon: require('../images/paas/web-icon.png'),
          top: 'Web',
          versions: '版本1.8.0.1',
          type: 'Javascript',
          url: 'web/sdk/WebSDK.zip',
        },
        {
          icon: require('../images/paas/xcx.png'),
          top: '小程序',
          versions: '版本1.4.1.5',
          type: 'Javascript',
          url: 'xcx/sdk/XcxSDK.rar',
        },
        {
          icon: require('../images/paas/gch.png'),
          top: '麒麟/统信/Windows',
          versions: '版本3.44.1.40',
          type: 'C++',
          url: 'gch/sdk/hst_sdk_3.44.1.40.zip',
        },
      ],
      // demo安装包下载
      demolist: [
        {
          icon: require('../images/paas/windows.png'),
          top: 'Windows',
          versions: '版本3.37.10.51',
          type: 'C++',
          url: 'windows/demo/WinMeetingDemo_installer_3.37.10.51.exe',
        },
        {
          icon: require('../images/paas/android-icon.png'),
          top: 'Android',
          versions: '版本3.36.11.29',
          type: 'Java',
          url: 'android/demo/AndroidMeetingDemo_V3.36.11.29.apk',
        },
        {
          icon: require('../images/paas/web-icon.png'),
          top: 'PC浏览器',
          versions: '版本1.8.0.1',
          type: 'Javascript',
          url: 'https://5uw.haoshitong.com:8443/webApp/#/login',
          isWebDemo: true,
        },
        {
          icon: require('../images/paas/wx-web-icon.png'),
          top: '微信浏览器',
          versions: '版本1.8.0.1',
          type: 'Javascript',
          url: 'https://5uw.haoshitong.com/mobile/#/login',
          isH5: true,
        },
        {
          icon: require('../images/paas/xcx.png'),
          top: '小程序',
          versions: '版本1.4.1.5',
          type: 'Javascript',
          url: '',
        },
      ],
      // demo示例代码下载
      codelist: [
        {
          icon: require('../images/paas/windows.png'),
          top: 'Windows',
          versions: '版本3.37.10.51',
          type: 'C++',
          url: 'windows/demoCode/WinMeetingDemo_source_3.37.10.51.7z',
        },
        {
          icon: require('../images/paas/android-icon.png'),
          top: 'Android',
          versions: '版本3.36.11.29',
          type: 'Java',
          url: 'android/demoCode/MeetingDemo.zip',
        },
        {
          icon: require('../images/paas/mac.png'),
          top: 'iOS',
          versions: '版本3.36.10.10',
          type: 'ObjectiveC',
          url: 'ios/demoCode/MeetingDemo.zip',
        },
        {
          icon: require('../images/paas/web-icon.png'),
          top: 'PC浏览器',
          versions: '版本1.8.0.1',
          type: 'Javascript',
          url: 'web/demoCode/web-demo-code-master.zip',
        },
        {
          icon: require('../images/paas/wx-web-icon.png'),
          top: '微信浏览器',
          versions: '版本1.8.0.1',
          type: 'Javascript',
          url: 'web/demoCode/mobile-demo-code-master.zip',
        },
        {
          icon: require('../images/paas/xcx.png'),
          top: '小程序',
          versions: '版本1.4.1.5',
          type: 'Javascript',
          url: 'xcx/code/xcx.zip',
        },
        {
          icon: require('../images/paas/gch.png'),
          top: '麒麟/统信/Windows',
          versions: '版本3.44.1.40',
          type: 'C++',
          url: 'gch/code/hst_demo_source_3.44.1.40.7z',
        },
      ],
      // api文档下载
      apiList: [
        {
          icon: require('../images/paas/windows.png'),
          top: 'Windows',
          versions: '版本3.37.10.51',
          type: 'C++',
          url: 'api/api-windows.html',
        },
        {
          icon: require('../images/paas/android-icon.png'),
          top: 'Android',
          versions: '版本3.36.9.7',
          type: 'Java',
          url: 'api/api-android.html',
        },
        {
          icon: require('../images/paas/mac.png'),
          top: 'iOS',
          versions: '版本3.36.10.6',
          type: 'ObjectiveC',
          url: 'api/api-ios.html',
        },
        {
          icon: require('../images/paas/web-icon.png'),
          top: 'Web',
          versions: '版本1.8.0.1',
          type: 'Javascript',
          url: 'api/api-web.html',
        },
        {
          icon: require('../images/paas/xcx.png'),
          top: '小程序',
          versions: '版本1.4.1.5',
          type: 'Javascript',
          url: 'api/api-xcx.html',
        },
        {
          icon: require('../images/paas/gch.png'),
          top: '麒麟/统信/Windows',
          versions: '版本3.44.1',
          type: 'C++',
          url: 'api/api-gch.html',
        },
      ],
      serverList: [
        {
          icon: require('../images/paas/server.png'),
          top: '服务器',
          versions: '版本4.34',
          // type: 'C++',
          url: 'api/api-serve.html',
        },
      ],
      isWXH5: false,
      showImg: false,
    };
  },
  mounted() {},
  methods: {
    handleSrc() {},
    srcDefault() {},
    download(item, type) {
      if ((type === 'xcx' && item.top === '小程序') || item.isH5) {
        this.showImg = true;
        if (item.isH5) this.isWXH5 = true;
        return;
      }
      if (item.type === 'Link') {
        const jumpUrl = window.location.origin + '/document/androidNew'
        window.open(jumpUrl, '_blank');
        return
      }
      const { url } = item;
      const downloadUrl = window.location.origin + '/aResourceDownload/' + url;
      const link = document.createElement('a');
      link.download = '';
      link.href = downloadUrl;
      link.click();
    },
    clickDemoItem(item) {
      const { isWebDemo, url, isH5 } = item;
      isWebDemo && window.open(url);
      isH5 && window.open(url)
    },
    closeBox() {
      this.isWXH5 = this.showImg = false;
    },
  },
};
</script>

<style lang="less" scoped>
#main {
  max-width: 12.2rem;
  margin: 0 auto;
  overflow: hidden;
  .loadBg {
    width: 100%;
    height: 140vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(8, 18, 44, 0.2);
    .imgBox {
      width: 3.6rem;
      height: 4.4rem;
      background-color: #fff;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        width: 2.1rem;
        height: 2.1rem;
        margin: 0 auto;
      }
      .imgTitle {
        text-align: left;
        padding: 0 20px;
        font-size: 0.14rem;
        color: #68696c;
        line-height: 51px;
        position: relative;
        img {
          width: 0.2rem;
          height: 0.2rem;
          position: absolute;
          top: 0.1rem;
          right: 0.1rem;
          cursor: pointer;
        }
      }
      .imgfoot {
        font-size: 0.14rem;
        font-weight: 500;
        color: #08122c;
        line-height: 1rem;
      }
      .closeBtn {
        width: 0.87rem;
        height: 0.38rem;
        line-height: 0.38rem;
        font-size: 0.14rem;
        cursor: pointer;
        background: #f6f4ff;
        color: #6552c8;
        border-radius: 10px;
        margin: 0.1rem auto;
      }
    }
  }
  .title {
    text-align: left;
    height: 0.22rem;
    line-height: 0.22rem;
    margin-bottom: 0.25rem;
    span {
      font-size: 0.2rem;
      font-family: Noto Sans S Chinese;
      font-weight: 400;
      color: #1c1d36;
    }
    img {
      display: inline-block;
      height: 0.14rem;
      margin-right: 0.2rem;
    }
  }
  > div {
    margin-top: 0.5rem;
    padding: 0.25rem;
    background-color: #f7f8f9;
    ul {
      text-align: left;
      li {
        display: inline-block;
        width: 221px;
        height: 91px;
        background: #ffffff;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        margin-right: 11px;
        margin-bottom: 9px;
        font-size: 12px;
        position: relative;
        vertical-align: top;
        img {
          display: inline-block;
          position: absolute;
        }
        .icon {
          left: 16px;
          top: 8px;
        }
        .btn {
          position: absolute;
          cursor: pointer;
          display: none;
          right: 18px;
          top: 20px;
          box-shadow: 0px 5px 10px 0px rgba(176, 176, 220, 0.84);
          border-radius: 50%;
          height: 48px;
          width: 48px;
          background: url('../images/paas/xzbtn1.png') no-repeat;
        }
        .txt {
          display: inline-block;
          margin-left: 56px;
          font-family: Noto Sans S Chinese;
          font-weight: 100;
          color: #afafb1;
          div {
            margin-bottom: 7px;
          }
          .top {
            font-size: 17px;
            font-family: Noto Sans S Chinese;
            font-weight: 500;
            color: #08122c;
            margin-bottom: 16px;
            margin-top: 12px;
            white-space: nowrap;
          }
        }
      }
      li:hover .btn {
        display: inline-block;
      }
      .btn:hover {
        background: url('../images/paas/xzbtn2.png') no-repeat;
      }
      li:hover {
        border: 1px solid #998cdc;
      }
    }
  }
}
</style>
