<template>
  <div class="download">
    <div
      v-for="(jsonItem, index) in jsonData"
      :key="index"
    >
      <div class="title">
        <Icon
          type="ios-arrow-forward"
          color="#D8D8D8"
        />
        <span>{{jsonItem.title}}</span></div>
      <Row
        type="flex"
        justify="start"
        class="download-container"
      >
        <Col
          :lg="8"
          :xs="24"
          class="card-container"
          v-for="(item, index) in jsonItem.data"
          :key="index"
        >
        <div class="download-card">
          <div class="thumbnail">
            <img
              :src="item.icon"
              :alt="item.title"
            >
          </div>
          <div class="info">
            <h3>{{item.title}}</h3>
            <p>适合平台：{{item.platform}}</p>
            <p>版本编号：{{item.version}}</p>
            <p>版本大小：{{item.size}}</p>
            <p>更新日期：{{item.updateDate}}</p>
          </div>
          <div class="bar">
            <span><a :href="item.downloadUrl" style="float:left; padding-left:5px">免费下载</a></span>
          </div>
        </div>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import { docUrl } from "../../service/api.js";

export default {
  data() {
    return {
      jsonData: ""
      // jsonData: [
      //   {
      //     title: "SDK下载",
      //     data: [
      //       {
      //         icon: require("../../images/download/download-icon01.png"),
      //         title: "SDK for Windows",
      //         platform: "XP/Windows 7/Windows 10",
      //         version: "1.0.3",
      //         size: "128MB",
      //         updateDate: "2019年6月10日",
      //         downloadUrl:
      //           "http://fs.hst.com/download/paas/sdk/windows/fsp_sdk_win32.zip"
      //       },
      //     ]
      //  }
      //]
    };
  },

  mounted() {
    fetch(docUrl + "config/sdk.json", {method: "GET", mode: "cors"}).then(res => {
        return res.json();
    }).then(data => {
      this.jsonData = data.jsonData;
    });
  }
};
</script>

<style scoped>
.title {
  padding: 0.19rem 0 0.2rem 0.3rem;
  font-size: 0.24rem;
  font-family: "PingFangSC-Medium";
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

.title * {
  vertical-align: middle;
}

.download {
  max-width: 13.66rem;
  margin: 0 auto;
  text-align: left;
  background: #ffffff;
  min-height: 93vh;
}

.download-container {
  margin: 0 0.2rem;
}

.card-container {
  padding: 0 0.1rem;
}

.download-card {
  border: 1px solid rgba(233, 233, 233, 1);
  margin-bottom: 0.2rem;
}

.download-card img {
  display: inline-block;
  max-width: 0.8rem;
  margin: 0.24rem 0.24rem 0 0.24rem;
}

.download-card .thumbnail {
  vertical-align: top;
  display: inline-block;
  width: 30%;
}

.download-card .info {
  display: inline-block;
  width: 70%;
  font-family: "PingFangSC-Regular";
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 0.22rem;
}

.info h3 {
  margin-top: 0.19rem;
  margin-bottom: 0.16rem;
  font-size: 0.16rem;
  font-family: "PingFangSC-Medium";
  font-weight: bold;
  line-height: 0.24rem;
}

.info p {
  margin-bottom: 0.08rem;
}

.bar {
  height: 0.48rem;
  background: #f7f9fa;
  padding: 0.1rem;
  font-family: "PingFangSC-Regular";
  font-weight: 400;
  font-size: 0.14rem;
  border-top: 1px solid rgba(233, 233, 233, 1);
  line-height: 0.28rem;
  text-align: center;
}

.bar span {
  display: inline-block;
}

.bar a {
  color: rgba(0, 0, 0, 0.45);
}

.bar a:hover {
  color: #7b8cff;
}

.download .ivu-icon.ivu-icon-ios-arrow-forward {
  font-size: 0.3rem;
}

@media screen and (max-width: 576px) {
  .card-container {
    padding: 0 0.3rem;
  }
}
</style>