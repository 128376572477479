import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isDeveloperModel: false,
    showLeftNav: false,
    isMobile: false,
  },
  mutations: {
    changeModel(state, model) {
      state.isDeveloperModel = model
    },

    switchLeftNav(state) {
      state.showLeftNav = !state.showLeftNav
    },

    changeMobile(state, payload) {
      state.isMobile = payload
    }
  },
  actions: {

  }
})