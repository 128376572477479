<template>
  <div class="code">
    <div
      v-for="(jsonItem, index) in jsonData"
      :key="index"
    >
      <!-- {{jsonData}} -->
      <div class="title">
        <Icon
          type="ios-arrow-forward"
          color="#D8D8D8"
        />
        <span>{{jsonItem.title}}</span>
      </div>
      <Row
        type="flex"
        class="code-container"
      >
        <Col
          :lg="6"
          :xs="24"
          class="card-container"
          v-for="(item, index) in jsonItem.data"
          :key="index"
        >
        <div class="code-card">
          <div class="thumbnail">
            <img
              :src="item.pic"
              alt="item.title"
            >
          </div>
          <div class="name">{{item.title}}</div>
          <div class="tool-bar">
            <a
              :href="item.url"
              target="_blank"
              class="git"
            >
              <img
                src="../../images/code/code-icon01.png"
                alt="github"
              >
              <p>GitHub</p>
              <Icon
                type="ios-arrow-forward"
                color="#D8D8D8"
              />
            </a>
            <div
              v-if="item.acc"
              @click="clickAcc(item)"
              class="experience"
            >
              <img
                :src="item.icon"
              >
              <p>{{item.acc}}</p>
            </div>
          </div>
        </div>
        </Col>
      </Row>
    </div>
    <Modal
      title="MeetingDemo"
      v-model="showModal"
      width="280rem"
      :closable="false"
    >
      <img
        :src="modalImg"
        alt="modalImg"
      >
    </Modal>
  </div>
</template>

<script>
import { docUrl } from "../../service/api.js";

export default {
  data() {
    return {
      showModal: false,
      modalImg: "",
      jsonData: [{}, {}, {}, {}]
      // jsonData: [
      //   {
      //     title: "MeetingDemo====",
      //     data: [
      //       {
      //         pic: require("../../images/code/code-pic01.png"),
      //         title: "Windows",
      //         url: "https://github.com/paas-hst/MeetingDemo_windows",
      //         acc: "下载体验",
      //         accUrl:
      //           "http://fs.hst.com/download/paas/meetingdemo/windows/meetingdemo_windows.zip"
      //       },
    };
  },

  methods: {
    clickAcc(item) {
      if (item.accUrl) {
        window.open(item.accUrl);
      } else if (item.accImg) {
        this.showModal = true;
        this.modalImg = item.accImg;
      }
    }
  },

  mounted() {
    fetch(docUrl + "config/demo.json", {method: "GET", mode: "cors"}).then(res => {
        return res.json();
    }).then(data => {
      this.jsonData = data.jsonData;
    });
  }
};
</script>

<style scoped>
.code {
  max-width: 13.66rem;
  margin: 0 auto;
  text-align: left;
  background: #ffffff;
  min-height: 93vh;
}

.title {
  font-size: 0.24rem;
  font-family: "PingFangSC-Medium";
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  padding-left: 0.42rem;
  padding-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.title * {
  vertical-align: middle;
}

.title .ivu-icon.ivu-icon-ios-arrow-forward {
  font-size: 0.35rem;
}

.code-container {
  margin: 0 0.3rem;
  max-width: 13rem;
}

.card-container {
  padding: 0.1rem 0.12rem;
}

.code-card {
  border: 0.01rem solid rgba(233, 233, 233, 1);
}

.code-card .thumbnail {
  border-radius: 0.02rem 0.02rem 0 0;
}

.code-card .thumbnail img {
  width: 100%;
}

.code-card .name {
  font-size: 0.16rem;
}

.code-card .name {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  text-align: center;
  font-family: "PingFangSC-Medium";
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

.tool-bar {
  text-align: center;
}

.tool-bar .experience {
  margin-left: 0.12rem;
}

.code-card .git,
.code-card .experience {
  cursor: pointer;
  display: inline-block;
  width: 1.25rem;
  height: 0.4rem;
  padding: 0.03rem 0;
  margin-bottom: 0.16rem;
  border-radius: 0.08rem;
  border: 0.01rem solid rgba(233, 233, 233, 1);
  transition: all 0.3s;
}

.code-card .experience:hover,
.code-card .git:hover {
  background: #f0f2f5;
}

.git *,
.experience * {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.git img {
  max-width: 0.24rem;
  height: 0.2rem;
  margin-right: 0.08rem;
}

.experience img {
  width: 0.2rem;
  /* margin-left: 0.06rem; */
  margin-right: 0.04rem;
}

.experience p,
.git p {
  font-family: "SourceHanSansCN-Regular";
  font-size: 0.16rem;
  font-weight: 400;
  color: #555555;
}

.git .ivu-icon.ivu-icon-ios-arrow-forward {
  font-size: 0.25rem;
}

@media screen and (max-width: 576px) {
  .code-container {
    margin: 0;
  }

  .card-container {
    padding: 0 0.7rem;
    margin-bottom: 0.5rem;
  }

  .code-card .name {
    font-size: 0.3rem;
  }

  .tool-bar .git,
  .tool-bar .experience {
    width: 1.8rem;
    height: 0.5rem;
    padding: 0;
    line-height: 0.5rem;
  }

  .experience p,
  .git p {
    font-size: 0.24rem;
  }

  .git .ivu-icon.ivu-icon-ios-arrow-forward {
    font-size: 0.35rem;
  }
}
</style>