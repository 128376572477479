<template>
  <div id="showdoc">
        <h1>api说明文档</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {
    
    }
  },
  mounted() {},
  methods: {
     
  },
}
</script>

<style lang="less" scoped>
#showdoc {
  max-width: 12.2rem;
  margin: 0 auto;
  overflow: hidden;
 h1{
    font-size: 0.2rem;
    text-align: center;
 }
}
</style>
