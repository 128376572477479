import Home from '../views/Home.vue'
import Developer from '../views/Developer.vue'
import SDK from '../views/sdk'
import Serverapi from '../views/serverapi'
import Download from '../views/Download.vue'

import Platform from '../views/developer/Platform.vue'
import Foundation from '../views/developer/Foundation.vue'
// import Document from '../views/developer/Document.vue'
import Sdk from '../views/developer/Sdk.vue'
import Demo from '../views/developer/Demo.vue'
import Code from '../views/developer/Code.vue'

import Document from '../views/document'
import androidNew from '../views/document/android_new'


export default [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/developer',
    name: 'developer',
    component: Developer,
    children: [
      {
        path: 'platform',
        name: 'platform',
        component: Platform
      },
      {
        path: 'foundation',
        name: 'foundation',
        component: Foundation
      },
      {
        path: 'document',
        name: 'document',
        component: Document
      },
      {
        path: 'sdk',
        name: 'sdk',
        component: Sdk
      },
      {
        path: 'demo',
        name: 'demo',
        component: Demo
      },
      {
        path: 'code',
        name: 'code',
        component: Code
      }
    ]
  },
  {
    path: '/document',
    name: 'document',
    component: Document,
  },
  {
    path: '/document/androidNew',
    name: 'androidSdk',
    component: androidNew
  },
  {
    path: '/sdk',
    name: 'sdk',
    component: SDK
  },
  {
    path: '/serverapi',
    name: 'serverapi',
    component: Serverapi
    // children: [
    //   {
    //     path: 'platform',
    //     name: 'platform',
    //     component: Platform
    //   },
    // ]
  },
  {
    path: '/download',
    name: 'download',
    component: Download
  }
]
