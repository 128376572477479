<template>
  <div id="showdoc">
        <div class="contents">
          <h1 id="pc-cpjj">私有云CES 4.34 API</h1>
          <h2 style="font-size: 0.25rem;" >产品简介</h2>
          <h2>产品概述</h2>
          <p>
            好视通aPaaS SDK（application Platform as a Service
            应用平台即服务SDK，简称应用SDK）是好视通将多年在远程教育、远程培训、远程招聘、应急指挥等行业积累的云服务能力提供给第三方开发者，帮助开发者快速的搭建全方位、低延迟、高质量的音视频解决方案。
          </p>
          <a id="pc-jgjs"></a>
          <h2>架构介绍</h2>
          <p>
            好视通SDK方案可以与好视通行业方案结合满足各种场景需求，客户可以通过服务器API以及应用SDK对业务进行管理。同时该方案也可以与好视通行业方案的软件、硬件共同使用，客户可通过SDK与自身业务软件相结合，打造垂直领域的解决方案。
          </p>
          <img src="../../../images/docSDK/pc-1.svg" alt="" />
          <a id="pc-gnjs"></a>
          <h2>功能介绍</h2>
          <p>API主要提供以下功能</p>
          <table>
            <tr>
              <th>功能</th>
              <th>功能说明</th>
            </tr>
            <tr>
              <td>接口鉴权</td>
              <td>为解决服务器接口访问安全性,对接口增加鉴权验证</td>
            </tr>
            <tr>
              <td>组织管理</td>
              <td>组织管理接口开放人员组织管理功能</td>
            </tr>
            <tr>
              <td>用户管理</td>
              <td>用户管理接口开放管理用户功能，负责用户的增删改查</td>
            </tr>
            <tr>
              <td>会议管理</td>
              <td>会议管理接口主要开放会议室的管理功能，包括会议室创建、修改、授权等</td>
            </tr>
            <tr>
              <td>录制接口</td>
              <td>录制接口主要开放服务器会议录制的管理功能，查询录制任务，获取录制数据等</td>
            </tr>
           
          </table>
            <h2 style="font-size: 0.25rem;" id="pc-ksrm">快速入门</h2>
            <h2>准备工作</h2>
          <p>
            私有云后台服务器API接入，请先准备私有云接入Key和Secret。支持好视通私有云CES 4.34及以上服务器版本
             Key 和 Secret 请联系服务提供商提供。
          </p>
          <a ></a>
          <h2>接入方式</h2>
          <p>
             私有云后台API为 RESTful接口，服务提供商提供的Key和Secret用于生成接口请求用的Authorization。每个接口请求HTTP Header 中带上Authorization 即可。
          </p>
           <img src="../../../images/docSDK/serveimg.png" alt="" />
             <h2>接口地址</h2>
          <p>
             添加用户的接口地址为/api/v1/user/add ，服务器端口默认8443最终以服务部署为准，则最终接口地址为
          </p>
           <img src="../../../images/paas/serverurl.png" alt="" />
        </div>

        <div class="right-nav">
           <Anchor show-ink>
             <AnchorLink href="#pc-cpjj" title="产品简介" :scroll-offset="60"></AnchorLink>
             <!-- <AnchorLink href="#pc-jgjs" title="架构介绍" :scroll-offset="60"></AnchorLink> -->
             <AnchorLink href="#pc-gnjs" title="功能介绍" :scroll-offset="60"></AnchorLink>
             <AnchorLink href="#pc-ksrm" title="快速入门" :scroll-offset="60"></AnchorLink>
           </Anchor>
         </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
    
    }
  },
  mounted() {},
  methods: {
   
  },
}
</script>

<style lang="less" scoped>
#showdoc {
  max-width: 10.2rem;
  margin: 0 auto;
  overflow: hidden;
  padding: 0.3rem 0.3rem 1rem 0rem;
 .contents{
   position: relative;
   padding-right:0.6rem;
   display: inline-block;
   h1{
     font-size: 0.32rem;
     padding-bottom: 0.2rem;
     margin-bottom: 0.2rem;
     font-family: 'PingFangSC-Medium';
     line-height: 0.24rem;
     font-weight: bold;
     color: #262626;
    }
    h2{
     font-family: 'PingFangSC-Medium';
     font-size: 0.21rem;
     line-height: 0.24rem;
     font-weight: bold;
     color: #262626;
     margin-top: 0.28rem;
     margin-bottom: 0.1rem;
    }
    p{
     font-size: 0.15rem;
     font-family: 'PingFangSC-Regular';
     font-weight: 400;
     color: #6f6f6f;
     line-height: 1.8;
     margin-bottom: 0.06rem;
    }
    table{
    font-family: verdana, arial, sans-serif;
    font-size: 0.14rem;
    color: #333333;
    border-width: 1px;
    border-color: #666666;
    border-collapse: collapse;
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
    tr {
    vertical-align: middle;
    th{
      border-width: 1px;
      padding: 9px;
      border-style: solid;
      border-color: #666666;
      background-color: #dedede;
    }
    td{
      border-width: 1px;
      padding: 8px;
      border-style: solid;
      border-color: #666666;
      background-color: #ffffff;
    }
    }
 }
  
  }
  .right-nav {
    width: 15%;
    position: fixed;
    top: 2.05rem;
    // right: 2rem;
    display: inline-block;
    max-width: 2rem;
  }
}
</style>
