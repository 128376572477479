<template>
  <div id="document">
    <Dropdown @on-click="handleSelect" class="mls">
      <a href="javascript:void(0)">
        {{ showType }}
        <Icon type="ios-arrow-down"></Icon>
      </a>
      <DropdownMenu slot="list">
        <DropdownItem name="Windows"> Windows </DropdownItem>
        <DropdownItem name="Android" divided> Android </DropdownItem>
        <DropdownItem name="iOS" divided> iOS </DropdownItem>
        <DropdownItem name="Web" divided> Web </DropdownItem>
        <DropdownItem name="Xcx" divided> 小程序 </DropdownItem>
      </DropdownMenu>
    </Dropdown>
    <iframe :src="docPage" frameborder="0" sandbox="allow-scripts"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showType: "Windows",
      docType: "Windows",
    };
  },
  computed: {
    docPage() {
      return `/docs/${this.docType}.html`;
    },
  },
  methods: {
    handleSelect(v) {
       this.docType = v;
      this.showType = v;
      if(v=='Xcx') {
        this.showType = '小程序';
      }
    },
  },
};
</script>

<style lang="less" scoped>
iframe {
  width: 100%;
  height: calc(100vh - 56px);
}

//   .ivu-dropdown {
//     display: inline-block;
//     float: left;
//     margin-left: 260px;
//     margin-top: 2%;
// }

@media screen and (min-width:700px) and (max-width:1600px){
  .ivu-dropdown {
    display: inline-block;
    float: left;
    margin-left: 100px;
    margin-top: 2%;
}
  }


@media screen and (min-width:1600px) and (max-width:2800px){
    .ivu-dropdown {
       display: inline-block;
       float:left;
       margin-left: 270px;
       margin-top: 2%;
   }
 }

</style>
