<template>
  <!-- eslint-disable -->
  <div class="developer-nav">
    <Row class-name="lg-container nav-container">
      <Col :lg="0" :xs="3" v-if="this.acName == 'document' || this.acName == 'platform'" class="menu-btn">
        <img @click="switchLeftNav" src="../images/nav/nav-icon02.png" alt="menu" />
      </Col>
      <Col :lg="4" :xs="6" class-name="logo">
        <router-link to="/">
          <img src="../images/nav/logo-d.png" alt="好视通logo" />
        </router-link>
      </Col>
      <Col :lg="3" :xs="0">
        <span class="developer dev-center">
          <span>应用SDK</span>
        </span>
      </Col>
      <Col :lg="{ span: 10, offset: 2 }" :xs="0" class-name="menu-container">
        <Menu mode="horizontal" theme="dark" :active-name="acName">
          <MenuItem to="/document" name="document">文档</MenuItem>
          <MenuItem to="/sdk" name="sdk">SDK/API</MenuItem>
          <MenuItem to="/serverapi" name="serverapi">服务器API</MenuItem>
          <MenuItem to="/download" name="download">下载</MenuItem>
          <MenuItem onclick="doyoo.util.openChat('g=10063474');return false;" name="contactus">联系我们</MenuItem>
        </Menu>
      </Col>
      <Col :lg="0" :xs="{ span: 3, offset: offset }" class="menu-btn">
        <img @click="showMenu = !showMenu" src="../images/nav/nav-icon02.png" alt="menu" />
      </Col>
    </Row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false
    }
  },

  computed: {
    acName() {
      return this.$route.name
    },

    offset() {
      return this.$route.name == 'document' || this.$route.name == 'platform' ? 12 : 15
    }
  },

  methods: {
    switchLeftNav() {
      this.$store.commit('switchLeftNav')
    }
  }
}
</script>

<style lang="less" scoped>
.dev-center {
  padding: 2px 16px;
  background-color: #495eeb;
  height: 24px;
  border-radius: 12px;
}

.developer-nav {
  position: fixed;
  height: 0.56rem;
  background: #2e2c3f;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.nav-container {
  height: 0.56rem;
  max-width: 13.66rem;
  line-height: 0.56rem;
}

.logo {
  margin-top: 0.1rem;
  padding-left: 0.15rem;
}

.logo img {
  max-width: 1.8rem;
  height: 0.33rem;
  vertical-align: middle;
}

.developer {
  color: #ffffff;
  font-size: 0.14rem;
  font-family: 'SourceHanSansCN-Regular';
  font-weight: 400;
  vertical-align: middle;
  cursor: default;
}

// .developer:hover {
//   color: #7b8cff;
// }

.menu-item {
  margin-right: 0.44rem;
  line-height: 0.56rem;
  font-size: 0.22rem;
  font-family: 'SourceHanSansCN-Regular';
  font-weight: 400;
  border-bottom: 0.02rem solid #495eeb;
}

.ivu-menu-dark {
  background: #2e2c3f;
}

.ivu-menu-horizontal {
  height: 0.56rem;
  line-height: 0.56rem;
}

.ivu-menu-item,
.ivu-menu-submenu {
  font-family: 'SourceHanSansCN-Regular';
  font-size: 0.18rem;
}

.ivu-menu-horizontal.ivu-menu-dark:after {
  display: none;
}

.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-item-active,
.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-item:hover,
.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-submenu-active,
.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-submenu:hover {
  color: #7b8cff;
  border-bottom: 0.02rem solid #7b8cff;
}

.ivu-menu-vertical {
  width: 100% !important;
}

.ivu-menu-vertical .ivu-menu-item,
.ivu-menu-vertical .ivu-menu-submenu {
  border-bottom: 1px solid #e6e8f4;
}

.ivu-menu-submenu-title a {
  color: rgba(255, 255, 255, 0.7);
}

/deep/ .ivu-menu-horizontal .ivu-icon.ivu-icon-ios-arrow-down.ivu-menu-submenu-title-icon {
  display: none !important;
}

.login-btn,
.register-btn {
  cursor: pointer;
  display: inline-block;
  width: 0.8rem;
  text-align: center;
  vertical-align: middle;
  height: 0.32rem;
  line-height: 0.32rem;
  color: #ffffff;
  font-size: 0.16rem;
  font-weight: 500;
  border: 1px solid #495eeb;
  border-radius: 0.24rem;
  transition: all 300ms;
}

.login-btn {
  //background: #7b8cff;
  background: #495eeb;
  border-color: transparent;
  margin-right: 0.16rem;
}

// .login-btn:hover,
// .register-btn:hover {
//   background: #ffffff;
//   color: #495eeb;
//   border: 0px;
// }

.menu-btn img {
  width: 0.56rem;
  vertical-align: top;
  display: inline-block;
}

@media screen and (max-width: 576px) {
  .ivu-menu-submenu-title a {
    color: #000;
  }
}
</style>
