import fetch from '../lib/fetch';

/**
 * 获取文档内容 
 * @param {String} url 文件路径 
 */
// export const getDoc = (url) => fetch(githubUrl + 'repos/paas-hst/Documentation/contents/cn/' + url)

export const getMdDoc = (url) => 
  fetch("http://paas.hst.com/doc/" + url, {
    method: "GET",
    mode: "cors", 
    headers: {
      "accept": "text/html;charset=utf-8"
    }
  }).then(res => {
    return res.text();
  })

export const docUrl = "http://paas.hst.com/doc/";

export const getJsonDoc = (url) => 
  fetch("http://paas.hst.com/doc/" + url, {
    method: "GET",
    mode: "cors", 
    headers: {
      "accept": "application/json;charset=utf-8"
    }
  }).then(res => {
    return res.json();
  })