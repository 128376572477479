<template>
  <div class="home">
    <!-- 海报 -->
    <div class="banner-wrapper">
      <div class="banner">
        <img src="@/images/paas/1banner.png" alt="" />
        <div class="banner-title">
          应用SDK解决方案
        </div>
        <div class="banner-txt">
          与好视通进行集成，将应用能力融入应用中，与硬件打通，行成行业解决方案。
        </div>
        <div onclick="doyoo.util.openChat('g=10063474');return false;" class="banner-btn"></div>
      </div>
    </div>
    <!-- 应用解决方案 -->
    <div class="solution wrapper">
      <div class="title">应用SDK解决方案，将行业能力快速融入自己的应用中</div>
      <ul>
        <li v-for="(v, i) in solutionImg" :key="i" @mouseenter="handleSolution(i)" @mouseleave="handleSolution()">
          <img :src="v.imgUrl" alt="" />
          <transition name="slide-fade">
            <div class="mask" v-if="solutionSelect === i">
              <img src="@/images/paas/zz.png" alt="" />
              <span>{{ v.txt }}</span>
            </div>
          </transition>
        </li>
      </ul>
      <div class="solution-btn" @click="$router.push('/document')">查看详情</div>
    </div>

    <!--部署方式 -->
    <div class="deploy">
      <div class="title">应用SDK解决方案，将行业能力快速融入自己的应用中</div>
      <div class="main wrapper">
        <div class="option">
          <ul>
            <li v-for="(v, i) in deployImg" :key="i">
              <img
                :class="deploySelect === i ? 'active' : ''"
                :src="deploySelect === i ? v.activeUrl : v.titleUrl"
                alt=""
                @mouseenter="handleDeploy(i)"
              />
            </li>
          </ul>
        </div>
        <div class="content">
          <img :src="deployImg[deploySelect].detailUrl" alt="" />
          <div v-if="deploySelect === 0" class="txt">支持公有云、私有云、混合云三大部署方式</div>
          <div v-else-if="deploySelect === 1" class="txt">支持万人会议并发</div>
          <div v-else class="txt">提供多种平台SDK方案</div>
        </div>
        <div class="tips">
          <span>简单快捷集成应用能力</span>
          <span>提供场景化硬件方案</span>
        </div>
      </div>
      <div class="solution-btn" @click="$router.push('/document')">查看详情</div>
    </div>

    <!-- 音视频解决方案 -->
    <div class="banner-wrapper">
      <div class="banner">
        <img src="@/images/paas/2banner.png" alt="" />
        <div class="banner-title">
          音视频SDK解决方案
        </div>
        <div class="banner-txt">
          摆脱业务限制，客户自己控制音视频调用方案，构建属于自己的生态。
        </div>
        <div onclick="doyoo.util.openChat('g=10063474');return false;" class="banner-btn"></div>
      </div>
    </div>

    <!-- 底层能力方案 -->
    <div class="power wrapper">
      <div class="title">提供底层能力方案，满足客户自己组建各类场景的需要</div>
      <ul>
        <li
          v-for="(v, i) in power"
          :key="i"
          @mouseenter="handlePower(i, v)"
          @mouseleave="handlePower(undefined, {})"
        >
          <img :src="powerSelect === i ? v.activeUrl : v.url" alt="" />
          <span>{{ v.txt }}</span>
        </li>
      </ul>
      <div class="solution-btn" @click="handleApaas">查看详情</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      solutionSelect: undefined,
      deploySelect: 0,
      powerSelect: undefined,
      solutionImg: [
        { imgUrl: require('../images/paas/ycyl.png'), txt: '远程医疗' },
        { imgUrl: require('../images/paas/yjzh.png'), txt: '应急指挥' },
        { imgUrl: require('../images/paas/zxkt.png'), txt: '在线课堂' },
        { imgUrl: require('../images/paas/spkf.png'), txt: '视频客服' },
        { imgUrl: require('../images/paas/spms.png'), txt: '视频面试' },
        { imgUrl: require('../images/paas/ycpb.png'), txt: '远程评标' },
      ],
      deployImg: [
        {
          titleUrl: require('../images/paas/anniu1.png'),
          activeUrl: require('../images/paas/anniu1ac.png'),
          detailUrl: require('../images/paas/kuang1.png'),
          detail: '支持公有云、私有云、混合云三大部署方式',
        },
        {
          titleUrl: require('../images/paas/anniu2.png'),
          activeUrl: require('../images/paas/anniu2ac.png'),
          detailUrl: require('../images/paas/kuang2.png'),
          detail: '支持万人会议并发',
        },
        {
          titleUrl: require('../images/paas/anniu3.png'),
          activeUrl: require('../images/paas/anniu3ac.png'),
          detailUrl: require('../images/paas/kuang3.png'),
          detail: '提供多种平台SDK方案',
        },
      ],
      powerTxt: '音频通信',
      power: [
        {
          url: require('../images/paas/yptx1.png'),
          activeUrl: require('../images/paas/yptx2.png'),
          txt: '音频通信',
        },
        {
          url: require('../images/paas/sptx1.png'),
          activeUrl: require('../images/paas/sptx2.png'),
          txt: '视频通信',
        },
        {
          url: require('../images/paas/pmgx1.png'),
          activeUrl: require('../images/paas/pmgx2.png'),
          txt: '屏幕共享',
        },
        {
          url: require('../images/paas/dzbb1.png'),
          activeUrl: require('../images/paas/dzbb2.png'),
          txt: '电子白板',
        },
        {
          url: require('../images/paas/wjgx1.png'),
          activeUrl: require('../images/paas/wjgx2.png'),
          txt: '文档共享',
        },
        {
          url: require('../images/paas/hdzb1.png'),
          activeUrl: require('../images/paas/hdzb2.png'),
          txt: '互动直播',
        },
        {
          url: require('../images/paas/wzlt1.png'),
          activeUrl: require('../images/paas/wzlt2.png'),
          txt: '文字聊天',
        },
        {
          url: require('../images/paas/xltd1.png'),
          activeUrl: require('../images/paas/xltd2.png'),
          txt: '信令通道',
        },
      ],
    };
  },
  methods: {
    handleSolution(i) {
      this.solutionSelect = i;
    },
    handleDeploy(i) {
      this.deploySelect = i;
    },
    handlePower(i, v) {
      this.powerSelect = i;
      this.powerTxt = v.txt;
    },
    handleApaas() {
      console.log(this.powerTxt);
      window.open('http://paas.hst.com', '_blank');
    },
  },
};
</script>

<style lang="less" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active 用于 2.1.8 以下版本 */ {
  transform: translateX(10px);
  opacity: 0;
}
.wrapper {
  width: 12rem;
  margin: 0 auto;
}
.title {
  padding-top: 0.89rem;
  padding-bottom: 0.81rem;
  font-size: 0.2rem;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #6f7098;
}
.banner-wrapper {
  background: url('../images/paas/banner-background.png') repeat;
}
.banner {
  position: relative;
  margin: 0 auto;
  width: 19.2rem;
  img {
    margin: 0 auto;
  }
  .banner-title {
    position: absolute;
    left: 20%;
    top: 26%;
    color: #fff;
    font-size: 0.44rem;
    font-family: Noto Sans S Chinese;
    font-weight: 500;
  }
  .banner-txt {
    position: absolute;
    left: 20%;
    top: 45%;
    color: #c9c9f4;
    font-size: 0.18rem;
    font-family: Noto Sans S Chinese;
    font-weight: 300;
  }
  .banner-btn {
    position: absolute;
    cursor: pointer;
    background-image: url('../images/paas/lxbtn.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 2.12rem;
    height: 0.62rem;
    left: 20%;
    top: 66%;
    transition: all 0.3s;
    &:hover {
      background-image: url('../images/paas/lxbtn_hover.png');
    }
  }
}
.solution {
  // height: 914px;
  li {
    display: inline-block;
    position: relative;
    width: 3.125rem;
    height: 2.2rem;
    &:nth-child(1) {
      margin-bottom: 0.2rem;
    }
    &:nth-child(3) {
      margin-bottom: 0.2rem;
    }

    &:nth-child(2) {
      margin-bottom: 0.2rem;
      margin-left: 0.2rem;
      margin-right: 0.2rem;
    }
    &:nth-child(5) {
      margin-left: 0.2rem;
      margin-right: 0.2rem;
    }
    .mask {
      position: absolute;
      top: 0;
      left: 0;
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -0.6rem;
        margin-top: -0.13rem;
        width: 1.2rem;
        height: 0.27rem;
        font-size: 0.24rem;
        font-family: Noto Sans S Chinese;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .solution-btn {
    width: 1.8rem;
    height: 0.6rem;
    margin: 0.84rem auto 0.76rem auto;
    background-image: url('../images/paas/btn-style.png');
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 0.2rem;
    font-family: Noto Sans S Chinese;
    font-weight: normal;
    color: #ffffff;
    line-height: 0.6rem;
    cursor: pointer;
    border-radius: 1rem;
    box-shadow: 0px 6px 27px 0px rgba(69, 40, 151, 0.47);
    &:hover {
      background-image: url('../images/paas/btn-style_hover.png');
    }
  }
}
.deploy {
  background: #f7f7fb;
  height: 990px;
  .main {
    height: 6.3rem;
    background: url('../images/paas/background.png');
    overflow: hidden;
    .option {
      margin-top: 0.7rem;
      height: 1.2rem;
      margin-bottom: 0.55rem;
      li {
        display: inline-block;
        &:nth-child(2) {
          margin-left: 1.08rem;
          margin-right: 1.08rem;
        }
        img {
          cursor: pointer;
          border-radius: 50%;
        }
      }
      .active {
        width: 120px;
        box-shadow: 0px 17px 51px 0px rgba(80, 64, 167, 0.6);
        margin-bottom: 10px;
        background: #7565cc;
      }
    }

    .content {
      height: 1.69rem;
      margin-bottom: 0.74rem;
      position: relative;
      .txt {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 6.1rem;
        margin-left: -3.05rem; /* 宽度的一半 */
        font-size: 0.32rem;
        font-family: Noto Sans S Chinese;
        font-weight: 400;
        color: #ffffff;
      }
    }

    .tips {
      height: 0.88rem;
      font-size: 0.24rem;
      font-family: Noto Sans S Chinese;
      font-weight: 400;
      color: #6552c8;
      span {
        display: inline-block;
        background: #edebf6;
        border-radius: 10px;
        width: 4rem;
        height: 0.8rem;
        line-height: 0.8rem;
        margin: 10px;
      }
    }
  }
  .solution-btn {
    width: 1.8rem;
    height: 0.6rem;
    // margin: 0.84rem auto 0.76rem auto;
    margin: 0 auto;
    background-image: url('../images/paas/btn-style.png');
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 0.2rem;
    font-family: Noto Sans S Chinese;
    font-weight: normal;
    color: #ffffff;
    line-height: 0.6rem;
    cursor: pointer;
    border-radius: 1rem;
    box-shadow: 0px 6px 27px 0px rgba(69, 40, 151, 0.47);
    &:hover {
      background-image: url('../images/paas/btn-style_hover.png');
    }
  }
}
.power {
  overflow: hidden;
  ul {
    li {
      margin: 0 0.6rem 0.2rem 0.6rem;
      display: inline-block;
      cursor: pointer;
      span {
        font-size: 0.2rem;
        font-family: Noto Sans S Chinese;
        font-weight: 300;
        color: #3d3e61;
      }
    }
  }
  .solution-btn {
    width: 1.8rem;
    height: 0.6rem;
    margin: 1rem auto 1.45rem auto;
    background-image: url('../images/paas/btn-style.png');
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 0.2rem;
    font-family: Noto Sans S Chinese;
    font-weight: normal;
    color: #ffffff;
    line-height: 0.6rem;
    cursor: pointer;
    border-radius: 1rem;
    box-shadow: 0px 6px 27px 0px rgba(69, 40, 151, 0.47);
    &:hover {
      background-image: url('../images/paas/btn-style_hover.png');
    }
  }
}
</style>
