<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="g-doc">
    <div class="table-of-contents"><ul><li><a href="#sdkmanager28sdku7ba1u7406u7c7b29">SdkManager(SDK管理类)</a><ul><li><a href="#shareinstance0a3ca20id3dshareinstance53443e203ca3e">shareInstance
<a id=shareInstance5344> </a></a></li><li><a href="#initsdk0a3ca20id3dinitsdk53443e203ca3e">initSdk
<a id=initSdk5344> </a></a></li><li><a href="#setserver0a3ca20id3dsetserver53443e203ca3e">setServer
<a id=setServer5344> </a></a></li><li><a href="#setclientidinfo0a3ca20id3dsetclientidinfo53443e203ca3e">setClientIdInfo
<a id=setClientIdInfo5344> </a></a></li><li><a href="#getsdkversion0a3ca20id3dgetsdkversion53443e203ca3e">getSdkVersion
<a id=getSdkVersion5344> </a></a></li><li><a href="#releasesdk0a3ca20id3dreleasesdk53443e203ca3e">releaseSdk
<a id=releaseSdk5344> </a></a></li><li><a href="#getloginmanager0a3ca20id3dgetloginmanager53443e203ca3e">getLoginManager
<a id=getLoginManager5344> </a></a></li><li><a href="#getmeetingmanager0a3ca20id3dgetmeetingmanager53443e203ca3e">getMeetingManager
<a id=getMeetingManager5344> </a></a></li><li><a href="#getusermanager0a3ca20id3dgetusermanager53443e203ca3e">getUserManager
<a id=getUserManager5344> </a></a></li><li><a href="#getvideomanager0a3ca20id3dgetvideomanager53443e203ca3e">getVideoManager
<a id=getVideoManager5344> </a></a></li><li><a href="#getaudiomanager0a3ca20id3dgetaudiomanager53443e203ca3e">getAudioManager
<a id=getAudioManager5344> </a></a></li><li><a href="#getchatmanager0a3ca20id3dgetchatmanager53443e203ca3e">getChatManager
<a id=getChatManager5344> </a></a></li><li><a href="#getscreensharemanager0a3ca20id3dgetscreensharemanager53443e203ca3e">getScreenShareManager
<a id=getScreenShareManager5344> </a></a></li><li><a href="#getpermissionmanager0a3ca20id3dgetpermissionmanager53443e203ca3e">getPermissionManager
<a id=getPermissionManager5344> </a></a></li><li><a href="#startmeetingroom0a3ca20id3dstartmeetingroom53443e203ca3e">startMeetingRoom
<a id=startMeetingRoom5344> </a></a></li><li><a href="#exitmeeting0a3ca20id3dexitmeeting53443e203ca3e">exitMeeting
<a id=exitMeeting5344> </a></a></li><li><a href="#applicationdidenterbackground0a3ca20id3dapplicationdidenterbackground53443e203ca3e">applicationDidEnterBackground
<a id=applicationDidEnterBackground5344> </a></a></li><li><a href="#applicationwillenterforeground0a3ca20id3dapplicationwillenterforeground53443e203ca3e">applicationWillEnterForeground
<a id=applicationWillEnterForeground5344> </a></a></li><li><a href="#applicationwillterminate0a3ca20id3dapplicationwillterminate53443e203ca3e">applicationWillTerminate
<a id=applicationWillTerminate5344> </a></a></li></ul></li><li><a href="#loginmanager28u767bu5f55u7ba1u7406u7c7b29">LoginManager(登录管理类)</a><ul><li><a href="#addeventlistener0a3ca20id3daddeventlistener53483e203ca3e">addEventListener
<a id=addEventListener5348> </a></a></li><li><a href="#getloginusernickname0a3ca20id3dgetloginusernickname53483e203ca3e">getLoginUserNickName
<a id=getLoginUserNickName5348> </a></a></li><li><a href="#isinloging0a3ca20id3disinloging53483e203ca3e">isInLoging
<a id=isInLoging5348> </a></a></li><li><a href="#loginaccount0a3ca20id3dloginaccount53483e203ca3e">loginAccount
<a id=loginAccount5348> </a></a></li><li><a href="#loginroomid0a3ca20id3dloginroomid53483e203ca3e">loginRoomId
<a id=loginRoomId5348> </a></a></li><li><a href="#logout0a3ca20id3dlogout53483e203ca3e">logout
<a id=logout5348> </a></a></li></ul></li><li><a href="#imeetingmanager28u4f1au63a7u7ba1u7406u7c7b29">IMeetingManager(会控管理类)</a><ul><li><a href="#addeventlistener0a3ca20id3daddeventlistener53523e203ca3e">addEventListener
<a id=addEventListener5352> </a></a></li><li><a href="#broadcastlayout0a3ca20id3dbroadcastlayout53523e203ca3e">broadcastLayout
<a id=broadcastLayout5352> </a></a></li><li><a href="#getcurrentroomname0a3ca20id3dgetcurrentroomname53523e203ca3e">getCurrentRoomName
<a id=getCurrentRoomName5352> </a></a></li></ul></li><li><a href="#iusermanager28u53c2u4f1au8005u7ba1u7406u7c7b29">IUserManager(参会者管理类)</a><ul><li><a href="#addeventlistener0a3ca20id3daddeventlistener53563e203ca3e">addEventListener
<a id=addEventListener5356> </a></a></li><li><a href="#abandonthehost0a3ca20id3dabandonthehost53563e203ca3e">abandonTheHost
<a id=abandonTheHost5356> </a></a></li><li><a href="#abandonthemanager0a3ca20id3dabandonthemanager53563e203ca3e">abandonTheManager
<a id=abandonTheManager5356> </a></a></li><li><a href="#applytobehost0a3ca20id3dapplytobehost53563e203ca3e">applyToBeHost
<a id=applyToBeHost5356> </a></a></li><li><a href="#applytobemanager0a3ca20id3dapplytobemanager53563e203ca3e">applyToBeManager
<a id=applyToBeManager5356> </a></a></li><li><a href="#closemeeting0a3ca20id3dclosemeeting53563e203ca3e">closeMeeting
<a id=closeMeeting5356> </a></a></li><li><a href="#getallusers0a3ca20id3dgetallusers53563e203ca3e">getAllUsers
<a id=getAllUsers5356> </a></a></li><li><a href="#getcurrenthost0a3ca20id3dgetcurrenthost53563e203ca3e">getCurrentHost
<a id=getCurrentHost5356> </a></a></li><li><a href="#getlocaluser0a3ca20id3dgetlocaluser53563e203ca3e">getLocalUser
<a id=getLocalUser5356> </a></a></li><li><a href="#getlocalvideostate0a3ca20id3dgetlocalvideostate53563e203ca3e">getLocalVideoState
<a id=getLocalVideoState5356> </a></a></li><li><a href="#getuserinfo0a3ca20id3dgetuserinfo53563e203ca3e">getUserInfo
<a id=getUserInfo5356> </a></a></li><li><a href="#grantbehostforremoteuser0a3ca20id3dgrantbehostforremoteuser53563e203ca3e">grantBeHostForRemoteUser
<a id=grantBeHostForRemoteUser5356> </a></a></li><li><a href="#kickuser0a3ca20id3dkickuser53563e203ca3e">kickUser
<a id=kickUser5356> </a></a></li><li><a href="#modifyusernickname0a3ca20id3dmodifyusernickname53563e203ca3e">modifyUserNickname
<a id=modifyUserNickname5356> </a></a></li></ul></li><li><a href="#videomanager28u89c6u9891u7ba1u7406u7c7b29">VideoManager(视频管理类)</a><ul><li><a href="#addeventlistener0a3ca20id3daddeventlistener53603e203ca3e">addEventListener
<a id=addEventListener5360> </a></a></li><li><a href="#refreshwhenorientationchanged0a3ca20id3drefreshwhenorientationchanged53603e203ca3e">refreshWhenOrientationChanged
<a id=refreshWhenOrientationChanged5360> </a></a></li><li><a href="#startlocalvideoview0a3ca20id3dstartlocalvideoview53603e203ca3e">startLocalVideoView
<a id=startLocalVideoView5360> </a></a></li><li><a href="#stoplocalvideoview0a3ca20id3dstoplocalvideoview53603e203ca3e">stopLocalVideoView
<a id=stopLocalVideoView5360> </a></a></li><li><a href="#isfrontcamera0a3ca20id3disfrontcamera53603e203ca3e">isFrontCamera
<a id=isFrontCamera5360> </a></a></li><li><a href="#switchcamera0a3ca20id3dswitchcamera53603e203ca3e">switchCamera
<a id=switchCamera5360> </a></a></li><li><a href="#startremotevideoview0a3ca20id3dstartremotevideoview53603e203ca3e">startRemoteVideoView
<a id=startRemoteVideoView5360> </a></a></li><li><a href="#stopremotevideoview0a3ca20id3dstopremotevideoview53603e203ca3e">stopRemoteVideoView
<a id=stopRemoteVideoView5360> </a></a></li><li><a href="#pauseremotevideoview0a3ca20id3dpauseremotevideoview53603e203ca3e">pauseRemoteVideoView
<a id=pauseRemoteVideoView5360> </a></a></li><li><a href="#applybroadcastvideo0a3ca20id3dapplybroadcastvideo53603e203ca3e">applyBroadcastVideo
<a id=applyBroadcastVideo5360> </a></a></li><li><a href="#agreeapplyvideo0a3ca20id3dagreeapplyvideo53603e203ca3e">agreeApplyVideo
<a id=agreeApplyVideo5360> </a></a></li><li><a href="#broadcastvideo0a3ca20id3dbroadcastvideo53603e203ca3e">broadcastVideo
<a id=broadcastVideo5360> </a></a></li><li><a href="#setcameradisable0a3ca20id3dsetcameradisable53603e203ca3e">setCameraDisable
<a id=setCameraDisable5360> </a></a></li><li><a href="#iscameradisable0a3ca20id3discameradisable53603e203ca3e">isCameraDisable
<a id=isCameraDisable5360> </a></a></li><li><a href="#setbeautyenable0a3ca20id3dsetbeautyenable53603e203ca3e">setBeautyEnable
<a id=setBeautyEnable5360> </a></a></li><li><a href="#setmirrorenable0a3ca20id3dsetmirrorenable53603e203ca3e">setMirrorEnable
<a id=setMirrorEnable5360> </a></a></li><li><a href="#isdisplayinglocalvideo0a3ca20id3disdisplayinglocalvideo53603e203ca3e">isDisplayingLocalVideo
<a id=isDisplayingLocalVideo5360> </a></a></li></ul></li><li><a href="#audiomanager28u97f3u9891u7ba1u7406u7c7b29">AudioManager(音频管理类)</a><ul><li><a href="#addaudiocapturecallback0a3ca20id3daddaudiocapturecallback53643e203ca3e">addAudioCaptureCallback
<a id=addAudioCaptureCallback5364> </a></a></li><li><a href="#addeventlistener0a3ca20id3daddeventlistener53643e203ca3e">addEventListener
<a id=addEventListener5364> </a></a></li><li><a href="#agreeapplyauido0a3ca20id3dagreeapplyauido53643e203ca3e">agreeApplyAuido
<a id=agreeApplyAuido5364> </a></a></li><li><a href="#applybroadcastaudio0a3ca20id3dapplybroadcastaudio53643e203ca3e">applyBroadcastAudio
<a id=applyBroadcastAudio5364> </a></a></li><li><a href="#broadcastaudio0a3ca20id3dbroadcastaudio53643e203ca3e">broadcastAudio
<a id=broadcastAudio5364> </a></a></li><li><a href="#isappmute0a3ca20id3disappmute53643e203ca3e">isAppMute
<a id=isAppMute5364> </a></a></li><li><a href="#ismicrophoneenable0a3ca20id3dismicrophoneenable53643e203ca3e">isMicrophoneEnable
<a id=isMicrophoneEnable5364> </a></a></li><li><a href="#removeaudiocapturecallback0a3ca20id3dremoveaudiocapturecallback53643e203ca3e">removeAudioCaptureCallback
<a id=removeAudioCaptureCallback5364> </a></a></li><li><a href="#setallmute0a3ca20id3dsetallmute53643e203ca3e">setAllMute
<a id=setAllMute5364> </a></a></li><li><a href="#setappmute0a3ca20id3dsetappmute53643e203ca3e">setAppMute
<a id=setAppMute5364> </a></a></li><li><a href="#setmicrophoneenable0a3ca20id3dsetmicrophoneenable53643e203ca3e">setMicrophoneEnable
<a id=setMicrophoneEnable5364> </a></a></li></ul></li><li><a href="#screensharemanager28u5c4fu5e55u5171u4eabu7ba1u7406u7c7b29">ScreenShareManager(屏幕共享管理类)</a><ul><li><a href="#addeventlistener0a3ca20id3daddeventlistener53683e203ca3e">addEventListener
<a id=addEventListener5368> </a></a></li><li><a href="#isscreensharing0a3ca20id3disscreensharing53683e203ca3e">isScreenSharing
<a id=isScreenSharing5368> </a></a></li><li><a href="#refreshwhenlayoutchanged0a3ca20id3drefreshwhenlayoutchanged53683e203ca3e">refreshWhenLayoutChanged
<a id=refreshWhenLayoutChanged5368> </a></a></li><li><a href="#startremotescreenshareview0a3ca20id3dstartremotescreenshareview53683e203ca3e">startRemoteScreenShareView
<a id=startRemoteScreenShareView5368> </a></a></li><li><a href="#stopremotescreenshareview0a3ca20id3dstopremotescreenshareview53683e203ca3e">stopRemoteScreenShareView
<a id=stopRemoteScreenShareView5368> </a></a></li></ul></li><li><a href="#chatmanager28u804au5929u7ba1u7406u7c7b29">ChatManager(聊天管理类)</a><ul><li><a href="#addeventlistener0a3ca20id3daddeventlistener53723e203ca3e">addEventListener
<a id=addEventListener5372> </a></a></li><li><a href="#sendchatmessage0a3ca20id3dsendchatmessage53723e203ca3e">sendChatMessage
<a id=sendChatMessage5372> </a></a></li></ul></li><li><a href="#permissionmanager28u6743u9650u7ba1u7406u7c7b29">PermissionManager(权限管理类)</a><ul><li><a href="#addeventlistener0a3ca20id3daddeventlistener53763e203ca3e">addEventListener
<a id=addEventListener5376> </a></a></li><li><a href="#checkpemission0a3ca20id3dcheckpemission53763e203ca3e">checkPemission
<a id=checkPemission5376> </a></a></li><li><a href="#configpemission0a3ca20id3dconfigpemission53763e203ca3e">configPemission
<a id=configPemission5376> </a></a></li></ul></li><li><a href="#whiteboardmanageruff08u767du677fu7ba1u7406u7c7buff09">WhiteBoardManager（白板管理类）</a><ul><li><a href="#activethewhiteboard0a3ca20id3dactivethewhiteboard53803e203ca3e">ActiveTheWhiteBoard
<a id=ActiveTheWhiteBoard5380> </a></a></li><li><a href="#addeventlistener0a3ca20id3daddeventlistener53803e203ca3e">addEventListener
<a id=addEventListener5380> </a></a></li><li><a href="#clearlocalwbviewcache0a3ca20id3dclearlocalwbviewcache53803e203ca3e">clearLocalWBViewCache
<a id=clearLocalWBViewCache5380> </a></a></li><li><a href="#closeothervnc0a3ca20id3dcloseothervnc53803e203ca3e">closeOtherVNC
<a id=closeOtherVNC5380> </a></a></li><li><a href="#createwhiteboard0a3ca20id3dcreatewhiteboard53803e203ca3e">createWhiteBoard
<a id=createWhiteBoard5380> </a></a></li><li><a href="#enablemeetingmultidatasharer0a3ca20id3denablemeetingmultidatasharer53803e203ca3e">enableMeetingMultiDataSharer
<a id=enableMeetingMultiDataSharer5380> </a></a></li><li><a href="#getactivedatablock0a3ca20id3dgetactivedatablock53803e203ca3e">getActiveDataBlock
<a id=getActiveDataBlock5380> </a></a></li><li><a href="#getcuractivewhiteboard0a3ca20id3dgetcuractivewhiteboard53803e203ca3e">getCurActiveWhiteBoard
<a id=getCurActiveWhiteBoard5380> </a></a></li><li><a href="#getcurrentpencolor0a3ca20id3dgetcurrentpencolor53803e203ca3e">getCurrentPenColor
<a id=getCurrentPenColor5380> </a></a></li><li><a href="#getmarkwhiteboard0a3ca20id3dgetmarkwhiteboard53803e203ca3e">getMarkWhiteBoard
<a id=getMarkWhiteBoard5380> </a></a></li><li><a href="#getmeetingroomsharedusercount0a3ca20id3dgetmeetingroomsharedusercount53803e203ca3e">getMeetingRoomSharedUserCount
<a id=getMeetingRoomSharedUserCount5380> </a></a></li><li><a href="#getsavewhiteboardimage0a3ca20id3dgetsavewhiteboardimage53803e203ca3e">getSaveWhiteBoardImage
<a id=getSaveWhiteBoardImage5380> </a></a></li><li><a href="#getwhiteboardbyid0a3ca20id3dgetwhiteboardbyid53803e203ca3e">getWhiteBoardByID
<a id=getWhiteBoardByID5380> </a></a></li><li><a href="#getwhiteboardcount0a3ca20id3dgetwhiteboardcount53803e203ca3e">getWhiteBoardCount
<a id=getWhiteBoardCount5380> </a></a></li><li><a href="#getwhiteboarddatalist0a3ca20id3dgetwhiteboarddatalist53803e203ca3e">getWhiteBoardDataList
<a id=getWhiteBoardDataList5380> </a></a></li><li><a href="#getwhiteboardlist0a3ca20id3dgetwhiteboardlist53803e203ca3e">getWhiteBoardList
<a id=getWhiteBoardList5380> </a></a></li><li><a href="#ishiddenthumingclosebtn0a3ca20id3dishiddenthumingclosebtn53803e203ca3e">isHiddenThumingCloseBtn
<a id=isHiddenThumingCloseBtn5380> </a></a></li><li><a href="#ishiddenvncthumingdeletebtn0a3ca20id3dishiddenvncthumingdeletebtn53803e203ca3e">isHiddenVNCThumingDeleteBtn
<a id=isHiddenVNCThumingDeleteBtn5380> </a></a></li><li><a href="#isinmarkingvc0a3ca20id3disinmarkingvc53803e203ca3e">isInMarkingVC
<a id=isInMarkingVC5380> </a></a></li><li><a href="#reloadcurrentpage0a3ca20id3dreloadcurrentpage53803e203ca3e">reloadCurrentPage
<a id=reloadCurrentPage5380> </a></a></li><li><a href="#sendwbactiveinfo2remote0a3ca20id3dsendwbactiveinfo2remote53803e203ca3e">sendWBActiveInfo2Remote
<a id=sendWBActiveInfo2Remote5380> </a></a></li><li><a href="#setcurrentpencolor0a3ca20id3dsetcurrentpencolor53803e203ca3e">setCurrentPenColor
<a id=setCurrentPenColor5380> </a></a></li><li><a href="#setlocalactivewbobjwithblockobj0a3ca20id3dsetlocalactivewbobjwithblockobj53803e203ca3e">setLocalActiveWBObjWithblockObj
<a id=setLocalActiveWBObjWithblockObj5380> </a></a></li><li><a href="#setlocaldataactiveobjpos0a3ca20id3dsetlocaldataactiveobjpos53803e203ca3e">setLocalDataActiveObjPos
<a id=setLocalDataActiveObjPos5380> </a></a></li><li><a href="#setlocaldataactivetype0a3ca20id3dsetlocaldataactivetype53803e203ca3e">setLocalDataActiveType
<a id=setLocalDataActiveType5380> </a></a></li><li><a href="#setrotateangle0a3ca20id3dsetrotateangle53803e203ca3e">setRotateAngle
<a id=setRotateAngle5380> </a></a></li><li><a href="#setwbmarktool0a3ca20id3dsetwbmarktool53803e203ca3e">setWbMarkTool
<a id=setWbMarkTool5380> </a></a></li><li><a href="#setwhiteboardiscanmark0a3ca20id3dsetwhiteboardiscanmark53803e203ca3e">setWhiteBoardIsCanMark
<a id=setWhiteBoardIsCanMark5380> </a></a></li><li><a href="#setwhiteboardisthuming0a3ca20id3dsetwhiteboardisthuming53803e203ca3e">setWhiteBoardIsThuming
<a id=setWhiteBoardIsThuming5380> </a></a></li><li><a href="#startmarkwhiteboard0a3ca20id3dstartmarkwhiteboard53803e203ca3e">startMarkWhiteBoard
<a id=startMarkWhiteBoard5380> </a></a></li><li><a href="#stopmarkwhiteboard0a3ca20id3dstopmarkwhiteboard53803e203ca3e">stopMarkWhiteBoard
<a id=stopMarkWhiteBoard5380> </a></a></li><li><a href="#stopsharewhiteboard0a3ca20id3dstopsharewhiteboard53803e203ca3e">stopShareWhiteBoard
<a id=stopShareWhiteBoard5380> </a></a></li><li><a href="#whiteboardisthuming0a3ca20id3dwhiteboardisthuming53803e203ca3e">whiteBoardIsThuming
<a id=whiteBoardIsThuming5380> </a></a></li></ul></li><li><a href="#roomlistmanager28u4f1au8baeu5217u8868u7ba1u7406u7c7b29">RoomListManager(会议列表管理类)</a><ul><li><a href="#addeventlistener0a3ca20id3daddeventlistener53843e203ca3e">addEventListener
<a id=addEventListener5384> </a></a></li><li><a href="#createinstantmeeting0a3ca20id3dcreateinstantmeeting53843e203ca3e">createInstantMeeting
<a id=createInstantMeeting5384> </a></a></li><li><a href="#querycommonmeeting0a3ca20id3dquerycommonmeeting53843e203ca3e">queryCommonMeeting
<a id=queryCommonMeeting5384> </a></a></li><li><a href="#queryinstantmeeting0a3ca20id3dqueryinstantmeeting53843e203ca3e">queryInstantMeeting
<a id=queryInstantMeeting5384> </a></a></li><li><a href="#queryschedulmeeting0a3ca20id3dqueryschedulmeeting53843e203ca3e">querySchedulMeeting
<a id=querySchedulMeeting5384> </a></a></li></ul></li><li><a href="#contactmanager28u901au8bafu5f55u547cu53ebu7ba1u7406u7c7b29">ContactManager(通讯录呼叫管理类)</a><ul><li><a href="#acceptrejectinvite0a3ca20id3dacceptrejectinvite53883e203ca3e">acceptRejectInvite
<a id=acceptRejectInvite5388> </a></a></li><li><a href="#getonlinedeviceinfo0a3ca20id3dgetonlinedeviceinfo53883e203ca3e">getOnlineDeviceInfo
<a id=getOnlineDeviceInfo5388> </a></a></li><li><a href="#inviteusers0a3ca20id3dinviteusers53883e203ca3e">inviteUsers
<a id=inviteUsers5388> </a></a></li><li><a href="#issupportinvite0a3ca20id3dissupportinvite53883e203ca3e">isSupportInvite
<a id=isSupportInvite5388> </a></a></li><li><a href="#querydeptinfo0a3ca20id3dquerydeptinfo53883e203ca3e">queryDeptInfo
<a id=queryDeptInfo5388> </a></a></li><li><a href="#querydeptuser0a3ca20id3dquerydeptuser53883e203ca3e">queryDeptUser
<a id=queryDeptUser5388> </a></a></li><li><a href="#queryonlinestatuswithuserlist0a3ca20id3dqueryonlinestatuswithuserlist53883e203ca3e">queryOnlineStatusWithUserList
<a id=queryOnlineStatusWithUserList5388> </a></a></li><li><a href="#searchuserwithnickname0a3ca20id3dsearchuserwithnickname53883e203ca3e">searchUserWithNickName
<a id=searchUserWithNickName5388> </a></a></li></ul></li><li><a href="#managerdelegate">ManagerDelegate</a><ul><li><a href="#audiomanagerdelegate0a3ca20id3daudiomanagerdelegate53963e203ca3e">AudioManagerDelegate
<a id=AudioManagerDelegate5396> </a></a></li><li><a href="#chatmanagerdelegte0a3ca20id3dchatmanagerdelegte53963e203ca3e">ChatManagerDelegte
<a id=ChatManagerDelegte5396> </a></a></li><li><a href="#contactmanagerdelegate0a3ca20id3dcontactmanagerdelegate53963e203ca3e">ContactManagerDelegate
<a id=ContactManagerDelegate5396> </a></a></li><li><a href="#imeetingmanagerdelegate0a3ca20id3dimeetingmanagerdelegate53963e203ca3e">IMeetingManagerDelegate
<a id=IMeetingManagerDelegate5396> </a></a></li><li><a href="#iusermanagerdelegate0a3ca20id3diusermanagerdelegate53963e203ca3e">IUserManagerDelegate
<a id=IUserManagerDelegate5396> </a></a></li><li><a href="#loginmanagerdelegate0a3ca20id3dloginmanagerdelegate53963e203ca3e">LoginManagerDelegate
<a id=LoginManagerDelegate5396> </a></a></li><li><a href="#permissionmanagerdelegate0a3ca20id3dpermissionmanagerdelegate53963e203ca3e">PermissionManagerDelegate
<a id=PermissionManagerDelegate5396> </a></a></li><li><a href="#roomlistmanagerdelegate0a3ca20id3droomlistmanagerdelegate53963e203ca3e">RoomListManagerDelegate
<a id=RoomListManagerDelegate5396> </a></a></li><li><a href="#screensharemanagerdelegate0a3ca20id3dscreensharemanagerdelegate53963e203ca3e">ScreenShareManagerDelegate
<a id=ScreenShareManagerDelegate5396> </a></a></li><li><a href="#sdkmanagerdelegate0a3ca20id3dsdkmanagerdelegate53963e203ca3e">SdkManagerDelegate
<a id=SdkManagerDelegate5396> </a></a></li><li><a href="#videomanagerdelegate0a3ca20id3dvideomanagerdelegate53963e203ca3e">VideoManagerDelegate
<a id=VideoManagerDelegate5396> </a></a></li><li><a href="#whiteboardmanagerdelegate0a3ca20id3dwhiteboardmanagerdelegate53963e203ca3e">WhiteBoardManagerDelegate
<a id=WhiteBoardManagerDelegate5396> </a></a></li></ul></li><li><a href="#u5173u952eu7c7bu578bu5b9au4e49">关键类型定义</a><ul><li><a href="#errcode0a3ca20id3derrcode53923e203ca3e">ErrCode
<a id=ErrCode5392> </a></a></li><li><a href="#baseuserinfo0a3ca20id3dbaseuserinfo53923e203ca3e">BaseUserInfo
<a id=BaseUserInfo5392> </a></a></li><li><a href="#kuserpermissiontype0a3ca20id3dkuserpermissiontype53923e203ca3e">kUserPermissionType
<a id=kUserPermissionType5392> </a></a></li><li><a href="#kroompermissiontype0a3ca20id3dkroompermissiontype53923e203ca3e">kRoomPermissionType
<a id=kRoomPermissionType5392> </a></a></li></ul></li></ul></div>
    <div id="right" class="content-right">
     <h1 class="curproject-name"> iOS 3.36.10.6 </h1> 
<h1 id="sdkmanager28sdku7ba1u7406u7c7b29">SdkManager(SDK管理类)</h1>
<p></p>
<h2 id="shareinstance0a3ca20id3dshareinstance53443e203ca3e">shareInstance
<a id=shareInstance5344> </a></h2>
<p></p>
<h3 id="">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>单例模式，获取SdkManager单例引用
</code></pre>
<h3 id="-2">请求参数</h3>
<h3 id="-3">返回数据</h3>
<pre><code class="language-javascript">返回 SdkManager  单例对象
</code></pre>
<h2 id="initsdk0a3ca20id3dinitsdk53443e203ca3e">initSdk
<a id=initSdk5344> </a></h2>
<p></p>
<h3 id="-4">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>负责创建并初始化

用户管理IUserManager
登录管理LoginManager
会议管理IMeetingManager
聊天管理ChatManager
视频管理VideoManager
音频管理AudioManager
屏幕共享管理ShareScreenManager
权限管理类PermissionManager

SdkManager 为单例模式
</code></pre>
<h3 id="-5">请求参数</h3>
<h3 id="-6">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="setserver0a3ca20id3dsetserver53443e203ca3e">setServer
<a id=setServer5344> </a></h2>
<p></p>
<h3 id="-7">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>设置会议服务器地址和端口
</code></pre>
<h3 id="-8">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>NSString host</td>
<td>是</td>
<td></td>
<td>服务器IP地址</td>
</tr>
<tr>
<td>NSString port</td>
<td>是</td>
<td></td>
<td>服务器端口号</td>
</tr>
</tbody>
</table>
<h3 id="-9">返回数据</h3>
<pre><code class="language-javascript">返回值： 无
</code></pre>
<h2 id="setclientidinfo0a3ca20id3dsetclientidinfo53443e203ca3e">setClientIdInfo
<a id=setClientIdInfo5344> </a></h2>
<p></p>
<h3 id="-10">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>设置SDK的clientId 和clientSecret，clientId和clientSecret由服务器创建分发，可以从服务提供商处获取
</code></pre>
<h3 id="-11">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>NSString clientId</td>
<td>是</td>
<td></td>
<td>客户端Id</td>
</tr>
<tr>
<td>NSString clientSecret</td>
<td>是</td>
<td></td>
<td>秘钥</td>
</tr>
</tbody>
</table>
<h3 id="-12">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="getsdkversion0a3ca20id3dgetsdkversion53443e203ca3e">getSdkVersion
<a id=getSdkVersion5344> </a></h2>
<p></p>
<h3 id="-13">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取SDK 版本号
</code></pre>
<h3 id="-14">请求参数</h3>
<h3 id="-15">返回数据</h3>
<pre><code class="language-javascript">返回SDK版本号信息（例:V3.36.2.4）
</code></pre>
<h2 id="releasesdk0a3ca20id3dreleasesdk53443e203ca3e">releaseSdk
<a id=releaseSdk5344> </a></h2>
<p></p>
<h3 id="-16">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>进程退出时负责释放所有的Manager
</code></pre>
<h3 id="-17">请求参数</h3>
<h3 id="-18">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="getloginmanager0a3ca20id3dgetloginmanager53443e203ca3e">getLoginManager
<a id=getLoginManager5344> </a></h2>
<p></p>
<h3 id="-19">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取登录管理类LoginManager实例,管理类不需要用户自行创建，SDK初始化时会自动创建实例
</code></pre>
<h3 id="-20">请求参数</h3>
<h3 id="-21">返回数据</h3>
<pre><code class="language-javascript">返回LoginManager实例
</code></pre>
<h2 id="getmeetingmanager0a3ca20id3dgetmeetingmanager53443e203ca3e">getMeetingManager
<a id=getMeetingManager5344> </a></h2>
<p></p>
<h3 id="-22">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取会议管理类MeetingManager实例,管理类不需要用户自行创建，SDK初始化时会自动创建实例
</code></pre>
<h3 id="-23">请求参数</h3>
<h3 id="-24">返回数据</h3>
<pre><code class="language-javascript">返回IMeetingManager 实例
</code></pre>
<h2 id="getusermanager0a3ca20id3dgetusermanager53443e203ca3e">getUserManager
<a id=getUserManager5344> </a></h2>
<p></p>
<h3 id="-25">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取用户管理类UserManager实例,管理类不需要用户自行创建，SDK初始化时会自动创建实例
</code></pre>
<h3 id="-26">请求参数</h3>
<h3 id="-27">返回数据</h3>
<pre><code class="language-javascript">返回IUserManager 实例
</code></pre>
<h2 id="getvideomanager0a3ca20id3dgetvideomanager53443e203ca3e">getVideoManager
<a id=getVideoManager5344> </a></h2>
<p></p>
<h3 id="-28">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取视频管理类VideoManager实例,管理类不需要用户自行创建，SDK初始化时会自动创建实例
</code></pre>
<h3 id="-29">请求参数</h3>
<h3 id="-30">返回数据</h3>
<pre><code class="language-javascript">返回VideoManager 实例
</code></pre>
<h2 id="getaudiomanager0a3ca20id3dgetaudiomanager53443e203ca3e">getAudioManager
<a id=getAudioManager5344> </a></h2>
<p></p>
<h3 id="-31">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取音频管理类AudioManager实例,管理类不需要用户自行创建，SDK初始化时会自动创建实例
</code></pre>
<h3 id="-32">请求参数</h3>
<h3 id="-33">返回数据</h3>
<pre><code class="language-javascript">返回AudioManager实例
</code></pre>
<h2 id="getchatmanager0a3ca20id3dgetchatmanager53443e203ca3e">getChatManager
<a id=getChatManager5344> </a></h2>
<p></p>
<h3 id="-34">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取聊天管理类ChatManager实例,管理类不需要用户自行创建，SDK初始化时会自动创建实例
</code></pre>
<h3 id="-35">请求参数</h3>
<h3 id="-36">返回数据</h3>
<pre><code class="language-javascript">返回ChatManager实例
</code></pre>
<h2 id="getscreensharemanager0a3ca20id3dgetscreensharemanager53443e203ca3e">getScreenShareManager
<a id=getScreenShareManager5344> </a></h2>
<p></p>
<h3 id="-37">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取屏幕共享管理类ScreenShareManager实例,管理类不需要用户自行创建，SDK初始化时会自动创建实例
</code></pre>
<h3 id="-38">请求参数</h3>
<h3 id="-39">返回数据</h3>
<pre><code class="language-javascript">返回ScreenShareManager 实例
</code></pre>
<h2 id="getpermissionmanager0a3ca20id3dgetpermissionmanager53443e203ca3e">getPermissionManager
<a id=getPermissionManager5344> </a></h2>
<p></p>
<h3 id="-40">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取权限管理类PermissionManager实例,管理类不需要用户自行创建，SDK初始化时会自动创建实例
</code></pre>
<h3 id="-41">请求参数</h3>
<h3 id="-42">返回数据</h3>
<pre><code class="language-javascript">返回PermissionManager实例
</code></pre>
<h2 id="startmeetingroom0a3ca20id3dstartmeetingroom53443e203ca3e">startMeetingRoom
<a id=startMeetingRoom5344> </a></h2>
<p></p>
<h3 id="-43">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>登录入会成功之后，会中界面后弹出之后，需要调用此方法，调用之后才会收到会议室中的所有的音视频和会中消息事件
</code></pre>
<h3 id="-44">请求参数</h3>
<h3 id="-45">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="exitmeeting0a3ca20id3dexitmeeting53443e203ca3e">exitMeeting
<a id=exitMeeting5344> </a></h2>
<p></p>
<h3 id="-46">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>参会人员主动退出会议室，调用后会自动退出会议室释放音视频相关组件，断掉会中网络连接
</code></pre>
<h3 id="-47">请求参数</h3>
<h3 id="-48">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="applicationdidenterbackground0a3ca20id3dapplicationdidenterbackground53443e203ca3e">applicationDidEnterBackground
<a id=applicationDidEnterBackground5344> </a></h2>
<p></p>
<h3 id="-49">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>APP进入后台运行时调用，用于通知SDK进行保护措施，接口在AppDelegae中applicationDidEnterBackground调用
</code></pre>
<h3 id="-50">请求参数</h3>
<h3 id="-51">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="applicationwillenterforeground0a3ca20id3dapplicationwillenterforeground53443e203ca3e">applicationWillEnterForeground
<a id=applicationWillEnterForeground5344> </a></h2>
<p></p>
<h3 id="-52">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>App 由后台进入前台运行时调用，用于通知SDK进行保护措施，在AppDelegate中的applicationWillEnterForeground调用
</code></pre>
<h3 id="-53">请求参数</h3>
<h3 id="-54">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="applicationwillterminate0a3ca20id3dapplicationwillterminate53443e203ca3e">applicationWillTerminate
<a id=applicationWillTerminate5344> </a></h2>
<p></p>
<h3 id="-55">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>APP结束进程时调用，接口在AppDelegae中applicationWillTerminate 方法中调用
</code></pre>
<h3 id="-56">请求参数</h3>
<h3 id="-57">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h1 id="loginmanager28u767bu5f55u7ba1u7406u7c7b29">LoginManager(登录管理类)</h1>
<p></p>
<h2 id="addeventlistener0a3ca20id3daddeventlistener53483e203ca3e">addEventListener
<a id=addEventListener5348> </a></h2>
<p></p>
<h3 id="-58">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>登录监听回调，需要实现LoginManageDelegate 代理
</code></pre>
<h3 id="-59">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>id listener</td>
<td>是</td>
<td></td>
<td>监听回调类</td>
</tr>
</tbody>
</table>
<h3 id="-60">返回数据</h3>
<pre><code class="language-javascript">{&quot;type&quot;:&quot;object&quot;,&quot;title&quot;:&quot;title&quot;,&quot;properties&quot;:{}}
</code></pre>
<h2 id="getloginusernickname0a3ca20id3dgetloginusernickname53483e203ca3e">getLoginUserNickName
<a id=getLoginUserNickName5348> </a></h2>
<p></p>
<h3 id="-61">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p>/**<br>
&nbsp;* 获取账号登录后的昵称<br>
&nbsp;*<br>
&nbsp;* <strong>@return</strong> 登录后的昵称<br>
&nbsp;*/</p>
<h3 id="-62">请求参数</h3>
<h2 id="isinloging0a3ca20id3disinloging53483e203ca3e">isInLoging
<a id=isInLoging5348> </a></h2>
<p></p>
<h3 id="-63">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p>/**<br>
&nbsp;* 是否是正在登录中<br>
&nbsp;*<br>
&nbsp;* <strong>@return</strong> YES 正在登录&nbsp; NO 不是正在登录<br>
&nbsp;*/</p>
<h3 id="-64">请求参数</h3>
<h2 id="loginaccount0a3ca20id3dloginaccount53483e203ca3e">loginAccount
<a id=loginAccount5348> </a></h2>
<p></p>
<h3 id="-65">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>用户名密码登录登录成功后即可进入指定会议室，此时可以弹出会议室主UI
</code></pre>
<h3 id="-66">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>NSString  account</td>
<td>是</td>
<td></td>
<td>用户账号</td>
</tr>
<tr>
<td>NSString usrPwd</td>
<td>是</td>
<td></td>
<td>用户密码</td>
</tr>
<tr>
<td>NSString  rid</td>
<td>是</td>
<td></td>
<td>房间id</td>
</tr>
</tbody>
</table>
<h3 id="-67">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="loginroomid0a3ca20id3dloginroomid53483e203ca3e">loginRoomId
<a id=loginRoomId5348> </a></h2>
<p></p>
<h3 id="-68">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>邀请码入会,&nbsp; 会议室号入会。登录成功之后即可进入会议室主UI
</code></pre>
<h3 id="-69">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>NSString  roomId</td>
<td>是</td>
<td></td>
<td>房间id</td>
</tr>
<tr>
<td>NSString   pwd</td>
<td>是</td>
<td></td>
<td>房间密码</td>
</tr>
<tr>
<td>NSString   nickName</td>
<td>是</td>
<td></td>
<td>昵称</td>
</tr>
</tbody>
</table>
<h3 id="-70">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="logout0a3ca20id3dlogout53483e203ca3e">logout
<a id=logout5348> </a></h2>
<p></p>
<h3 id="-71">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p>退出登录</p>
<h3 id="-72">请求参数</h3>
<h1 id="imeetingmanager28u4f1au63a7u7ba1u7406u7c7b29">IMeetingManager(会控管理类)</h1>
<p></p>
<h2 id="addeventlistener0a3ca20id3daddeventlistener53523e203ca3e">addEventListener
<a id=addEventListener5352> </a></h2>
<p></p>
<h3 id="-73">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>会议室事件监听回调,需要实现EventListenerDelegate 代理
</code></pre>
<h3 id="-74">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>id  listener</td>
<td>是</td>
<td></td>
<td>监听回调对象</td>
</tr>
</tbody>
</table>
<h3 id="-75">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="broadcastlayout0a3ca20id3dbroadcastlayout53523e203ca3e">broadcastLayout
<a id=broadcastLayout5352> </a></h2>
<p></p>
<h3 id="-76">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>广播自己的布局给所有参会人，需要构造LayoutEvent 并广播
</code></pre>
<h3 id="-77">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>LayoutEvent</td>
<td>是</td>
<td></td>
<td>布局消息事件</td>
</tr>
</tbody>
</table>
<h3 id="-78">返回数据</h3>
<pre><code class="language-javascript">返回 ReturnCode错误码
</code></pre>
<h2 id="getcurrentroomname0a3ca20id3dgetcurrentroomname53523e203ca3e">getCurrentRoomName
<a id=getCurrentRoomName5352> </a></h2>
<p></p>
<h3 id="-79">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取当前会议名称
</code></pre>
<h3 id="-80">请求参数</h3>
<h3 id="-81">返回数据</h3>
<pre><code class="language-javascript">返回会议名称
</code></pre>
<h1 id="iusermanager28u53c2u4f1au8005u7ba1u7406u7c7b29">IUserManager(参会者管理类)</h1>
<p></p>
<h2 id="addeventlistener0a3ca20id3daddeventlistener53563e203ca3e">addEventListener
<a id=addEventListener5356> </a></h2>
<p></p>
<h3 id="-82">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>用户事件监听回调，需要实现EventListenerDelegate 代理
</code></pre>
<h3 id="-83">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>id   listener</td>
<td>是</td>
<td></td>
<td>监听回调对象</td>
</tr>
</tbody>
</table>
<h3 id="-84">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="abandonthehost0a3ca20id3dabandonthehost53563e203ca3e">abandonTheHost
<a id=abandonTheHost5356> </a></h2>
<p></p>
<h3 id="-85">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>本地用户放弃主持人身份
</code></pre>
<h3 id="-86">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>BaseUserInfo  userInfo</td>
<td>是</td>
<td></td>
<td>放弃主持人用户信息</td>
</tr>
</tbody>
</table>
<h3 id="-87">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="abandonthemanager0a3ca20id3dabandonthemanager53563e203ca3e">abandonTheManager
<a id=abandonTheManager5356> </a></h2>
<p></p>
<h3 id="-88">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>放弃管理员身份
</code></pre>
<h3 id="-89">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>int  userID</td>
<td>是</td>
<td></td>
<td>放弃用户id</td>
</tr>
</tbody>
</table>
<h3 id="-90">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="applytobehost0a3ca20id3dapplytobehost53563e203ca3e">applyToBeHost
<a id=applyToBeHost5356> </a></h2>
<p></p>
<h3 id="-91">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>参会人申请/放弃申请主持人
</code></pre>
<h3 id="-92">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>bool  apply</td>
<td>是</td>
<td></td>
<td>YES：申请  NO:放弃申请</td>
</tr>
<tr>
<td>BaseUserInfo  userInfo</td>
<td>是</td>
<td></td>
<td>申请用户信息</td>
</tr>
</tbody>
</table>
<h3 id="-93">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="applytobemanager0a3ca20id3dapplytobemanager53563e203ca3e">applyToBeManager
<a id=applyToBeManager5356> </a></h2>
<p></p>
<h3 id="-94">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>本地用户申请成为管理员
</code></pre>
<h3 id="-95">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>NSString  hostPwd</td>
<td>是</td>
<td></td>
<td>申请管理员权限的密码</td>
</tr>
</tbody>
</table>
<h3 id="-96">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="closemeeting0a3ca20id3dclosemeeting53563e203ca3e">closeMeeting
<a id=closeMeeting5356> </a></h2>
<p></p>
<h3 id="-97">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>关闭会议室，调用此接口后会议室中参会人员都会被请出会议室，需要权限支持
</code></pre>
<h3 id="-98">请求参数</h3>
<h3 id="-99">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="getallusers0a3ca20id3dgetallusers53563e203ca3e">getAllUsers
<a id=getAllUsers5356> </a></h2>
<p></p>
<h3 id="-100">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取当前会议室所有用户信息
</code></pre>
<h3 id="-101">请求参数</h3>
<h3 id="-102">返回数据</h3>
<pre><code class="language-javascript">返回所有用户信息(用户信息BaseUserInfo类)的数组
</code></pre>
<h2 id="getcurrenthost0a3ca20id3dgetcurrenthost53563e203ca3e">getCurrentHost
<a id=getCurrentHost5356> </a></h2>
<p></p>
<h3 id="-103">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取当前会议室中主持人，若会中不存在主持人则返回为nil
</code></pre>
<h3 id="-104">请求参数</h3>
<h3 id="-105">返回数据</h3>
<pre><code class="language-javascript">返回BaseUserInfo对象
</code></pre>
<h2 id="getlocaluser0a3ca20id3dgetlocaluser53563e203ca3e">getLocalUser
<a id=getLocalUser5356> </a></h2>
<p></p>
<h3 id="-106">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取本地用户信息
</code></pre>
<h3 id="-107">请求参数</h3>
<h3 id="-108">返回数据</h3>
<pre><code class="language-javascript">返回本地用户信息BaseUserInfo对象
</code></pre>
<h2 id="getlocalvideostate0a3ca20id3dgetlocalvideostate53563e203ca3e">getLocalVideoState
<a id=getLocalVideoState5356> </a></h2>
<p></p>
<h3 id="-109">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>本地用户视频预览是否打开
</code></pre>
<h3 id="-110">请求参数</h3>
<h3 id="-111">返回数据</h3>
<pre><code class="language-javascript">返回值BOOL ，YES:打开，NO:未打开
</code></pre>
<h2 id="getuserinfo0a3ca20id3dgetuserinfo53563e203ca3e">getUserInfo
<a id=getUserInfo5356> </a></h2>
<p></p>
<h3 id="-112">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>根据用户ID获取用户信息
</code></pre>
<h3 id="-113">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>int   userID</td>
<td>是</td>
<td></td>
<td>用户ID</td>
</tr>
</tbody>
</table>
<h3 id="-114">返回数据</h3>
<pre><code class="language-javascript">返回BaseUserInfo
</code></pre>
<h2 id="grantbehostforremoteuser0a3ca20id3dgrantbehostforremoteuser53563e203ca3e">grantBeHostForRemoteUser
<a id=grantBeHostForRemoteUser5356> </a></h2>
<p></p>
<h3 id="-115">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>授予远端用户为主持人角色，一般管理员有权限设置参会人为主持人角色
</code></pre>
<h3 id="-116">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>BaseUserInfo  userInfo</td>
<td>是</td>
<td></td>
<td>授予对象用户信息</td>
</tr>
</tbody>
</table>
<h3 id="-117">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="kickuser0a3ca20id3dkickuser53563e203ca3e">kickUser
<a id=kickUser5356> </a></h2>
<p></p>
<h3 id="-118">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>会中将某个参会人请出会议室，调用此接口后被请出的参会人会收到消息，收到消息后退出会议室即可
</code></pre>
<h3 id="-119">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>int  userId</td>
<td>是</td>
<td></td>
<td>管理员用户ID</td>
</tr>
<tr>
<td>int  kickedUserId</td>
<td>是</td>
<td></td>
<td>被踢出用户的用户ID</td>
</tr>
</tbody>
</table>
<h3 id="-120">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="modifyusernickname0a3ca20id3dmodifyusernickname53563e203ca3e">modifyUserNickname
<a id=modifyUserNickname5356> </a></h2>
<p></p>
<h3 id="-121">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>修改参会人昵称
</code></pre>
<h3 id="-122">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>BaseUserInfo   userInfo</td>
<td>是</td>
<td></td>
<td>目标用户信息</td>
</tr>
<tr>
<td>NSString  nickName</td>
<td>是</td>
<td></td>
<td>用户新昵称</td>
</tr>
</tbody>
</table>
<h3 id="-123">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h1 id="videomanager28u89c6u9891u7ba1u7406u7c7b29">VideoManager(视频管理类)</h1>
<p></p>
<h2 id="addeventlistener0a3ca20id3daddeventlistener53603e203ca3e">addEventListener
<a id=addEventListener5360> </a></h2>
<p></p>
<h3 id="-124">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>视频监听回调，需要实现EventListenerDelegate 代理
</code></pre>
<h3 id="-125">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>id  listener</td>
<td>是</td>
<td></td>
<td>监听回调对象</td>
</tr>
</tbody>
</table>
<h3 id="-126">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="refreshwhenorientationchanged0a3ca20id3drefreshwhenorientationchanged53603e203ca3e">refreshWhenOrientationChanged
<a id=refreshWhenOrientationChanged5360> </a></h2>
<p></p>
<h3 id="-127">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>通知SDK屏幕旋转后需要调用此接口，此接口内部会调整摄像头数据采集方向
</code></pre>
<h3 id="-128">请求参数</h3>
<h3 id="-129">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="startlocalvideoview0a3ca20id3dstartlocalvideoview53603e203ca3e">startLocalVideoView
<a id=startLocalVideoView5360> </a></h2>
<p></p>
<h3 id="-130">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>开启本地视频预览，传入UIView，视频将会在该UIView上渲染出来
</code></pre>
<h3 id="-131">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>UIView   preview</td>
<td>是</td>
<td></td>
<td>本地视频渲染视图</td>
</tr>
</tbody>
</table>
<h3 id="-132">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="stoplocalvideoview0a3ca20id3dstoplocalvideoview53603e203ca3e">stopLocalVideoView
<a id=stopLocalVideoView5360> </a></h2>
<p></p>
<h3 id="-133">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>停止预览本地视频
</code></pre>
<h3 id="-134">请求参数</h3>
<h3 id="-135">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="isfrontcamera0a3ca20id3disfrontcamera53603e203ca3e">isFrontCamera
<a id=isFrontCamera5360> </a></h2>
<p></p>
<h3 id="-136">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>判断是否是前置摄像头
</code></pre>
<h3 id="-137">请求参数</h3>
<h3 id="-138">返回数据</h3>
<pre><code class="language-javascript">返回BOOL类型  YES：前置摄像头，NO：后置摄像头
</code></pre>
<h2 id="switchcamera0a3ca20id3dswitchcamera53603e203ca3e">switchCamera
<a id=switchCamera5360> </a></h2>
<p></p>
<h3 id="-139">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>切换前后摄像头，参数详见CameraType
</code></pre>
<h3 id="-140">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>CameraType  type</td>
<td>是</td>
<td></td>
<td>FrontCamera:前置  BackCamera:后置</td>
</tr>
</tbody>
</table>
<h3 id="-141">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="startremotevideoview0a3ca20id3dstartremotevideoview53603e203ca3e">startRemoteVideoView
<a id=startRemoteVideoView5360> </a></h2>
<p></p>
<h3 id="-142">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>开始渲染显示远端用户的视频。当收到远端用户成功广播视频的消息之后，需要调用本方法进行远端视频的渲染展示
</code></pre>
<h3 id="-143">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>BaseUserInfo    user</td>
<td>是</td>
<td></td>
<td>用户信息</td>
</tr>
<tr>
<td>int    vid</td>
<td>是</td>
<td></td>
<td>视频通道ID</td>
</tr>
<tr>
<td>UIView   render</td>
<td>是</td>
<td></td>
<td>渲染视频</td>
</tr>
<tr>
<td>id    target</td>
<td>是</td>
<td></td>
<td>监听回调的对象</td>
</tr>
<tr>
<td>SEL  action</td>
<td>是</td>
<td></td>
<td>回调方法</td>
</tr>
</tbody>
</table>
<h3 id="-144">返回数据</h3>
<pre><code class="language-javascript">渲染所生成的RenderId， 需要将此RenderId保存起来，因为停止视频渲染的时候需要传入此RenderId
</code></pre>
<h2 id="stopremotevideoview0a3ca20id3dstopremotevideoview53603e203ca3e">stopRemoteVideoView
<a id=stopRemoteVideoView5360> </a></h2>
<p></p>
<h3 id="-145">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>停止远端视频的渲染展示。 renderId 为startRemoteVideoView 所返回的参数
</code></pre>
<h3 id="-146">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>BaseUserInfo   user</td>
<td>是</td>
<td></td>
<td>用户信息</td>
</tr>
<tr>
<td>int   vid</td>
<td>是</td>
<td></td>
<td>视频通道ID</td>
</tr>
<tr>
<td>int   renderId</td>
<td>是</td>
<td></td>
<td>视频渲染id(开始接收远端视频底层库返回并保存渲染id)</td>
</tr>
</tbody>
</table>
<h3 id="-147">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="pauseremotevideoview0a3ca20id3dpauseremotevideoview53603e203ca3e">pauseRemoteVideoView
<a id=pauseRemoteVideoView5360> </a></h2>
<p></p>
<h3 id="-148">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>暂停接收和显示远端视频，一般用于屏幕显未能展示的广播用户，可以暂停接收和渲染来节省电量和网络，比如UIScrollView来滑动显示视频，未显示的用户可以调用pauseRemoteVideoView
</code></pre>
<h3 id="-149">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>BaseUserInfo   user</td>
<td>是</td>
<td></td>
<td>用户信息</td>
</tr>
<tr>
<td>int   vid</td>
<td>是</td>
<td></td>
<td>视频通道ID</td>
</tr>
<tr>
<td>int   renderId</td>
<td>是</td>
<td></td>
<td>视频渲染id(开始接收远端视频底层库返回并保存渲染id)</td>
</tr>
<tr>
<td>bool   isPause</td>
<td>是</td>
<td></td>
<td>是否暂停（  YES - 暂停， NO- 继续接收）</td>
</tr>
</tbody>
</table>
<h3 id="-150">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="applybroadcastvideo0a3ca20id3dapplybroadcastvideo53603e203ca3e">applyBroadcastVideo
<a id=applyBroadcastVideo5360> </a></h2>
<p></p>
<h3 id="-151">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>用户申请广播自己或他人的视频或者关闭申请。调用此方法后所有参会用户会收到本用户的广播的申请，有权限同意的用户可以调用agreeApplyVideo接口进行同意/不同意用户申请。
</code></pre>
<h3 id="-152">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>bool  apply</td>
<td>是</td>
<td></td>
<td>YES - 申请， NO- 放弃</td>
</tr>
<tr>
<td>BaseUserInfo   user</td>
<td>是</td>
<td></td>
<td>用户信息</td>
</tr>
<tr>
<td>int  vid</td>
<td>是</td>
<td></td>
<td>视频通道ID</td>
</tr>
</tbody>
</table>
<h3 id="-153">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="agreeapplyvideo0a3ca20id3dagreeapplyvideo53603e203ca3e">agreeApplyVideo
<a id=agreeApplyVideo5360> </a></h2>
<p></p>
<h3 id="-154">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>同意/不同意远端用户的广播视频的请求,调用此接口需要权限。同意之后，所有的参会人员会收到用户视频广播的消息，收到这个消息后各参会人需要调用startRemoteVideoView来进行该用户视频的渲染展示
</code></pre>
<h3 id="-155">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>bool  agree</td>
<td>是</td>
<td></td>
<td>YES - 同意， NO- 拒绝</td>
</tr>
<tr>
<td>BaseUserInfo  user</td>
<td>是</td>
<td></td>
<td>用户信息</td>
</tr>
<tr>
<td>int  vid</td>
<td>是</td>
<td></td>
<td>视频通道ID</td>
</tr>
</tbody>
</table>
<h3 id="-156">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="broadcastvideo0a3ca20id3dbroadcastvideo53603e203ca3e">broadcastVideo
<a id=broadcastVideo5360> </a></h2>
<p></p>
<h3 id="-157">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>广播用户视频,可以是本地用户也可以是远端用户。vid为用户视通道信息，可以从BaseUserInfo中的HSTVideoChannel获取通道信息。HSTVideoChannel与摄像头一一对应。当其他参会用户收到本用户的广播消息之后，便可调用startRemoteVideoView 进行视频的渲染。
</code></pre>
<h3 id="-158">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>bool  open</td>
<td>是</td>
<td></td>
<td>YES - 广播， NO- 关闭</td>
</tr>
<tr>
<td>BaseUserInfo  user</td>
<td>是</td>
<td></td>
<td>用户信息</td>
</tr>
<tr>
<td>int  vid</td>
<td>是</td>
<td></td>
<td>视频通道ID</td>
</tr>
</tbody>
</table>
<h3 id="-159">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="setcameradisable0a3ca20id3dsetcameradisable53603e203ca3e">setCameraDisable
<a id=setCameraDisable5360> </a></h2>
<p></p>
<h3 id="-160">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>设置本地摄像头状态，本地视频广播状态下，禁用摄像头后远端则收不到本地视频流
</code></pre>
<h3 id="-161">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>bool   enable</td>
<td>是</td>
<td></td>
<td>YES - 启用摄像头， NO- 禁用摄像头</td>
</tr>
</tbody>
</table>
<h3 id="-162">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="iscameradisable0a3ca20id3discameradisable53603e203ca3e">isCameraDisable
<a id=isCameraDisable5360> </a></h2>
<p></p>
<h3 id="-163">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>查询摄像头是否是禁用状态
</code></pre>
<h3 id="-164">请求参数</h3>
<h3 id="-165">返回数据</h3>
<pre><code class="language-javascript">返回BOOL值（ YES - 启用， NO- 禁用）
</code></pre>
<h2 id="setbeautyenable0a3ca20id3dsetbeautyenable53603e203ca3e">setBeautyEnable
<a id=setBeautyEnable5360> </a></h2>
<p></p>
<h3 id="-166">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>设置是否启用美颜功能
</code></pre>
<h3 id="-167">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>bool   enable</td>
<td>是</td>
<td></td>
<td>YES-开启美颜或者NO-关闭美颜</td>
</tr>
</tbody>
</table>
<h3 id="-168">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="setmirrorenable0a3ca20id3dsetmirrorenable53603e203ca3e">setMirrorEnable
<a id=setMirrorEnable5360> </a></h2>
<p></p>
<h3 id="-169">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>设置是否启用视频镜像功能
</code></pre>
<h3 id="-170">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>bool   enable</td>
<td>是</td>
<td></td>
<td>YES（开启视频镜像） 或者NO（关闭视频镜像）</td>
</tr>
</tbody>
</table>
<h3 id="-171">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="isdisplayinglocalvideo0a3ca20id3disdisplayinglocalvideo53603e203ca3e">isDisplayingLocalVideo
<a id=isDisplayingLocalVideo5360> </a></h2>
<p></p>
<h3 id="-172">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>查询是否正在本地查看视频
</code></pre>
<h3 id="-173">请求参数</h3>
<h3 id="-174">返回数据</h3>
<pre><code class="language-javascript">返回BOOL值，YES-正在查看本地视频，NO-本地视频查看关闭
</code></pre>
<h1 id="audiomanager28u97f3u9891u7ba1u7406u7c7b29">AudioManager(音频管理类)</h1>
<p></p>
<h2 id="addaudiocapturecallback0a3ca20id3daddaudiocapturecallback53643e203ca3e">addAudioCaptureCallback
<a id=addAudioCaptureCallback5364> </a></h2>
<p></p>
<h3 id="-175">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>增加音频旁路监听回调
</code></pre>
<h3 id="-176">请求参数</h3>
<h3 id="-177">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="addeventlistener0a3ca20id3daddeventlistener53643e203ca3e">addEventListener
<a id=addEventListener5364> </a></h2>
<p></p>
<h3 id="-178">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>音频监听回调，需要实现EventListenerDelegate 代理
</code></pre>
<h3 id="-179">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>id   listener</td>
<td>是</td>
<td></td>
<td>监听回调对象</td>
</tr>
</tbody>
</table>
<h3 id="-180">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="agreeapplyauido0a3ca20id3dagreeapplyauido53643e203ca3e">agreeApplyAuido
<a id=agreeApplyAuido5364> </a></h2>
<p></p>
<h3 id="-181">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>同意/不同意用户的申请发言
</code></pre>
<h3 id="-182">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>bool  agree</td>
<td>是</td>
<td></td>
<td>同意/不同意</td>
</tr>
<tr>
<td>BaseUserInfo  user</td>
<td>是</td>
<td></td>
<td>申请发言用户</td>
</tr>
</tbody>
</table>
<h3 id="-183">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="applybroadcastaudio0a3ca20id3dapplybroadcastaudio53643e203ca3e">applyBroadcastAudio
<a id=applyBroadcastAudio5364> </a></h2>
<p></p>
<h3 id="-184">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code> 本地用户申请广播自己的音频或者关闭申请。调用此方法后所有参会用户会收到本用户的广播音频的申请，有权限同意的用户可以调用agreeApplyAudio接口进行同意/不同意用户申请。
</code></pre>
<h3 id="-185">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>bool   apply</td>
<td>是</td>
<td></td>
<td>申请或放弃发言</td>
</tr>
<tr>
<td>BaseUserInfo  user</td>
<td>是</td>
<td></td>
<td>申请或放弃发言用户</td>
</tr>
</tbody>
</table>
<h3 id="-186">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="broadcastaudio0a3ca20id3dbroadcastaudio53643e203ca3e">broadcastAudio
<a id=broadcastAudio5364> </a></h2>
<p></p>
<h3 id="-187">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p>广播/关闭广播 用户音频</p>
<h3 id="-188">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>bool   open</td>
<td>是</td>
<td></td>
<td>打开或者关闭</td>
</tr>
<tr>
<td>BaseUserInfo  user</td>
<td>是</td>
<td></td>
<td>打开或者关闭音频用户</td>
</tr>
</tbody>
</table>
<h3 id="-189">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="isappmute0a3ca20id3disappmute53643e203ca3e">isAppMute
<a id=isAppMute5364> </a></h2>
<p></p>
<h3 id="-190">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p><span class="colour" style="color: rgb(93, 108, 121);"><span class="colour" style="color: rgba(0, 0, 0, 0.85);">&nbsp;</span>获取手机静音状态</span></p>
<h3 id="-191">请求参数</h3>
<h3 id="-192">返回数据</h3>
<pre><code class="language-javascript">返回BOOL（  YES - 静音，NO - 非静音）
</code></pre>
<h2 id="ismicrophoneenable0a3ca20id3dismicrophoneenable53643e203ca3e">isMicrophoneEnable
<a id=isMicrophoneEnable5364> </a></h2>
<p></p>
<h3 id="-193">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p><span class="colour" style="color:rgb(93, 108, 121)">查询麦克风是否可用</span></p>
<h3 id="-194">请求参数</h3>
<h3 id="-195">返回数据</h3>
<pre><code class="language-javascript">返回YES（可用），NO（不可用）
</code></pre>
<h2 id="removeaudiocapturecallback0a3ca20id3dremoveaudiocapturecallback53643e203ca3e">removeAudioCaptureCallback
<a id=removeAudioCaptureCallback5364> </a></h2>
<p></p>
<h3 id="-196">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p><br data-tomark-pass=""><br>
<br data-tomark-pass=""></p>
<pre><code>移除音频旁路监听回调
</code></pre>
<h3 id="-197">请求参数</h3>
<h3 id="-198">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="setallmute0a3ca20id3dsetallmute53643e203ca3e">setAllMute
<a id=setAllMute5364> </a></h2>
<p></p>
<h3 id="-199">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p>管理员或主持人设置全场静音</p>
<h3 id="-200">请求参数</h3>
<h3 id="-201">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="setappmute0a3ca20id3dsetappmute53643e203ca3e">setAppMute
<a id=setAppMute5364> </a></h2>
<p></p>
<h3 id="-202">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p><span class="colour" style="color: rgb(93, 108, 121);">设置当前APP静音</span></p>
<h3 id="-203">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>BOOL  mute</td>
<td>是</td>
<td></td>
<td>YES: 静音 NO：非静音</td>
</tr>
</tbody>
</table>
<h3 id="-204">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="setmicrophoneenable0a3ca20id3dsetmicrophoneenable53643e203ca3e">setMicrophoneEnable
<a id=setMicrophoneEnable5364> </a></h2>
<p></p>
<h3 id="-205">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p>禁用或者启用麦克风</p>
<h3 id="-206">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>bool   enable</td>
<td>是</td>
<td></td>
<td>YES( 启用麦克风)或者NO（ 禁用麦克风）</td>
</tr>
</tbody>
</table>
<h3 id="-207">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h1 id="screensharemanager28u5c4fu5e55u5171u4eabu7ba1u7406u7c7b29">ScreenShareManager(屏幕共享管理类)</h1>
<p></p>
<h2 id="addeventlistener0a3ca20id3daddeventlistener53683e203ca3e">addEventListener
<a id=addEventListener5368> </a></h2>
<p></p>
<h3 id="-208">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>屏幕共享监听回调，需要实现EventListenerDelegate 代理
</code></pre>
<h3 id="-209">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>id   listener</td>
<td>是</td>
<td></td>
<td>监听回调类</td>
</tr>
</tbody>
</table>
<h3 id="-210">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="isscreensharing0a3ca20id3disscreensharing53683e203ca3e">isScreenSharing
<a id=isScreenSharing5368> </a></h2>
<p></p>
<h3 id="-211">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>是否正在接收屏幕共享
</code></pre>
<h3 id="-212">请求参数</h3>
<h3 id="-213">返回数据</h3>
<pre><code class="language-javascript">返回值BOOL类型  YES：正在接收屏幕共享， NO：没有接收屏幕共享
</code></pre>
<h2 id="refreshwhenlayoutchanged0a3ca20id3drefreshwhenlayoutchanged53683e203ca3e">refreshWhenLayoutChanged
<a id=refreshWhenLayoutChanged5368> </a></h2>
<p></p>
<h3 id="-214">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>屏幕旋转，layout布局变化，需要刷新
</code></pre>
<h3 id="-215">请求参数</h3>
<h3 id="-216">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="startremotescreenshareview0a3ca20id3dstartremotescreenshareview53683e203ca3e">startRemoteScreenShareView
<a id=startRemoteScreenShareView5368> </a></h2>
<p></p>
<h3 id="-217">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>开始接收渲染远端用户发起的屏幕共享
</code></pre>
<h3 id="-218">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>UIView   preview</td>
<td>是</td>
<td></td>
<td>屏幕共享渲染视图</td>
</tr>
<tr>
<td>int  dwStateUserID</td>
<td>是</td>
<td></td>
<td>屏幕共享者的用户ID</td>
</tr>
<tr>
<td>ShareScreenNotifyBlock  notify</td>
<td>是</td>
<td></td>
<td>屏幕共享状态回调</td>
</tr>
</tbody>
</table>
<h3 id="-219">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="stopremotescreenshareview0a3ca20id3dstopremotescreenshareview53683e203ca3e">stopRemoteScreenShareView
<a id=stopRemoteScreenShareView5368> </a></h2>
<p></p>
<h3 id="-220">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>停止接收渲染远端的屏幕共享
</code></pre>
<h3 id="-221">请求参数</h3>
<h3 id="-222">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h1 id="chatmanager28u804au5929u7ba1u7406u7c7b29">ChatManager(聊天管理类)</h1>
<p></p>
<h2 id="addeventlistener0a3ca20id3daddeventlistener53723e203ca3e">addEventListener
<a id=addEventListener5372> </a></h2>
<p></p>
<h3 id="-223">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>监听聊天消息事件，需要实现EventListenerDelegate 代理
</code></pre>
<h3 id="-224">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>id listener</td>
<td>是</td>
<td></td>
<td>监听回调对象</td>
</tr>
</tbody>
</table>
<h3 id="-225">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="sendchatmessage0a3ca20id3dsendchatmessage53723e203ca3e">sendChatMessage
<a id=sendChatMessage5372> </a></h2>
<p></p>
<h3 id="-226">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>向会议室中发送聊天消息
</code></pre>
<h3 id="-227">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>ChatMessageInfo    chatMsgInfo</td>
<td>是</td>
<td></td>
<td>聊天消息对象</td>
</tr>
</tbody>
</table>
<h3 id="-228">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h1 id="permissionmanager28u6743u9650u7ba1u7406u7c7b29">PermissionManager(权限管理类)</h1>
<p></p>
<h2 id="addeventlistener0a3ca20id3daddeventlistener53763e203ca3e">addEventListener
<a id=addEventListener5376> </a></h2>
<p></p>
<h3 id="-229">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>监听用户权限变更通知
</code></pre>
<h3 id="-230">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>id listener</td>
<td>是</td>
<td></td>
<td>监听回调对象</td>
</tr>
</tbody>
</table>
<h3 id="-231">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="checkpemission0a3ca20id3dcheckpemission53763e203ca3e">checkPemission
<a id=checkPemission5376> </a></h2>
<p></p>
<h3 id="-232">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>检查用户是否有某项权限，具体的权限类型参见kUserPermissionType
</code></pre>
<h3 id="-233">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>kUserPermissionType permissonType</td>
<td>是</td>
<td></td>
<td>权限类型</td>
</tr>
<tr>
<td>dwUserID</td>
<td>是</td>
<td></td>
<td>用户ID</td>
</tr>
</tbody>
</table>
<h3 id="-234">返回数据</h3>
<pre><code class="language-javascript">    PER_DISABLE = 0,    //无权限
PER_ENABLE = 1,     //有权限
PER_APPLY = 2       //需申请
</code></pre>
<h2 id="configpemission0a3ca20id3dconfigpemission53763e203ca3e">configPemission
<a id=configPemission5376> </a></h2>
<p></p>
<h3 id="-235">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>移动端暂时没有设置权限的功能，故此接口是个空实现
</code></pre>
<h3 id="-236">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>kUserPermissionType permissonType</td>
<td>是</td>
<td></td>
<td>权限类型</td>
</tr>
<tr>
<td>dwUserID</td>
<td>是</td>
<td></td>
<td>用户ID</td>
</tr>
</tbody>
</table>
<h3 id="-237">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h1 id="whiteboardmanageruff08u767du677fu7ba1u7406u7c7buff09">WhiteBoardManager（白板管理类）</h1>
<p></p>
<h2 id="activethewhiteboard0a3ca20id3dactivethewhiteboard53803e203ca3e">ActiveTheWhiteBoard
<a id=ActiveTheWhiteBoard5380> </a></h2>
<p></p>
<h3 id="-238">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>激活某个白板
</code></pre>
<h3 id="-239">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>DataBlockView *activeWbView</td>
<td>是</td>
<td></td>
<td>需要激活白板视图</td>
</tr>
</tbody>
</table>
<h3 id="-240">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="addeventlistener0a3ca20id3daddeventlistener53803e203ca3e">addEventListener
<a id=addEventListener5380> </a></h2>
<p></p>
<h3 id="-241">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>白板监听回调，需要实现EventListenerDelegate 代理
</code></pre>
<h3 id="-242">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>id   listener</td>
<td>是</td>
<td></td>
<td>监听回调对象</td>
</tr>
</tbody>
</table>
<h3 id="-243">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="clearlocalwbviewcache0a3ca20id3dclearlocalwbviewcache53803e203ca3e">clearLocalWBViewCache
<a id=clearLocalWBViewCache5380> </a></h2>
<p></p>
<h3 id="-244">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p><span class="colour" style="color: rgb(26, 83, 1);">清空本地白板视图数据缓存</span></p>
<h3 id="-245">请求参数</h3>
<h3 id="-246">返回数据</h3>
<pre><code class="language-javascript">返回错误码，详情请查看ReturnCode定义
</code></pre>
<h2 id="closeothervnc0a3ca20id3dcloseothervnc53803e203ca3e">closeOtherVNC
<a id=closeOtherVNC5380> </a></h2>
<p></p>
<h3 id="-247">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>关闭他人屏幕共享
</code></pre>
<h3 id="-248">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>int     userId</td>
<td>是</td>
<td></td>
<td>他人用户id</td>
</tr>
<tr>
<td>_DataType   dataType</td>
<td>是</td>
<td></td>
<td>数据类型</td>
</tr>
<tr>
<td>int   rightsIndex</td>
<td>是</td>
<td></td>
<td>权限类型</td>
</tr>
</tbody>
</table>
<h3 id="-249">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="createwhiteboard0a3ca20id3dcreatewhiteboard53803e203ca3e">createWhiteBoard
<a id=createWhiteBoard5380> </a></h2>
<p></p>
<h3 id="-250">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>创建白板（传入参数为白板名称）
</code></pre>
<h3 id="-251">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>NSString  * whiteBoardName</td>
<td>是</td>
<td></td>
<td>白板名称</td>
</tr>
</tbody>
</table>
<h3 id="-252">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="enablemeetingmultidatasharer0a3ca20id3denablemeetingmultidatasharer53803e203ca3e">enableMeetingMultiDataSharer
<a id=enableMeetingMultiDataSharer5380> </a></h2>
<p></p>
<h3 id="-253">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>会议是否可以多人共享
</code></pre>
<h3 id="-254">请求参数</h3>
<h3 id="-255">返回数据</h3>
<pre><code class="language-javascript">返回BOOL值（YES:可以多人共享  NO:不可以多人共享）
</code></pre>
<h2 id="getactivedatablock0a3ca20id3dgetactivedatablock53803e203ca3e">getActiveDataBlock
<a id=getActiveDataBlock5380> </a></h2>
<p></p>
<h3 id="-256">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取当前激活数据块
</code></pre>
<h3 id="-257">请求参数</h3>
<h3 id="-258">返回数据</h3>
<pre><code class="language-javascript">返回当前激活数据块（数据块为DataBlockObj类型）
</code></pre>
<h2 id="getcuractivewhiteboard0a3ca20id3dgetcuractivewhiteboard53803e203ca3e">getCurActiveWhiteBoard
<a id=getCurActiveWhiteBoard5380> </a></h2>
<p></p>
<h3 id="-259">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取当前激活的白板视图
</code></pre>
<h3 id="-260">请求参数</h3>
<h3 id="-261">返回数据</h3>
<pre><code class="language-javascript">返回激活白板视图（WhiteBoardView类型）
</code></pre>
<h2 id="getcurrentpencolor0a3ca20id3dgetcurrentpencolor53803e203ca3e">getCurrentPenColor
<a id=getCurrentPenColor5380> </a></h2>
<p></p>
<h3 id="-262">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取当前画笔颜色
</code></pre>
<h3 id="-263">请求参数</h3>
<h3 id="-264">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="getmarkwhiteboard0a3ca20id3dgetmarkwhiteboard53803e203ca3e">getMarkWhiteBoard
<a id=getMarkWhiteBoard5380> </a></h2>
<p></p>
<h3 id="-265">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取当前激活且要标注的白板
</code></pre>
<h3 id="-266">请求参数</h3>
<h3 id="-267">返回数据</h3>
<pre><code class="language-javascript">返回当前激活且要标注的白板视图（WhiteBoardView类型）
</code></pre>
<h2 id="getmeetingroomsharedusercount0a3ca20id3dgetmeetingroomsharedusercount53803e203ca3e">getMeetingRoomSharedUserCount
<a id=getMeetingRoomSharedUserCount5380> </a></h2>
<p></p>
<h3 id="-268">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取会中共享用户个数
</code></pre>
<h3 id="-269">请求参数</h3>
<h3 id="-270">返回数据</h3>
<pre><code class="language-javascript">返回会中共享用户个数
</code></pre>
<h2 id="getsavewhiteboardimage0a3ca20id3dgetsavewhiteboardimage53803e203ca3e">getSaveWhiteBoardImage
<a id=getSaveWhiteBoardImage5380> </a></h2>
<p></p>
<h3 id="-271">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取保存白板图片
</code></pre>
<h3 id="-272">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>WhiteBoardView * view</td>
<td>是</td>
<td></td>
<td>白板视图</td>
</tr>
</tbody>
</table>
<h3 id="-273">返回数据</h3>
<pre><code class="language-javascript">返回图片对象
</code></pre>
<h2 id="getwhiteboardbyid0a3ca20id3dgetwhiteboardbyid53803e203ca3e">getWhiteBoardByID
<a id=getWhiteBoardByID5380> </a></h2>
<p></p>
<h3 id="-274">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>通过ID获取白板
</code></pre>
<h3 id="-275">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>int  wbId</td>
<td>是</td>
<td></td>
<td>白板数据id</td>
</tr>
<tr>
<td>_DataType   dataType</td>
<td>是</td>
<td></td>
<td>数据类型</td>
</tr>
</tbody>
</table>
<h3 id="-276">返回数据</h3>
<pre><code class="language-javascript">返回获取白板视图（WhiteBoardView类型）
</code></pre>
<h2 id="getwhiteboardcount0a3ca20id3dgetwhiteboardcount53803e203ca3e">getWhiteBoardCount
<a id=getWhiteBoardCount5380> </a></h2>
<p></p>
<h3 id="-277">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取当前白板数量
</code></pre>
<h3 id="-278">请求参数</h3>
<h3 id="-279">返回数据</h3>
<pre><code class="language-javascript">返回白板数量
</code></pre>
<h2 id="getwhiteboarddatalist0a3ca20id3dgetwhiteboarddatalist53803e203ca3e">getWhiteBoardDataList
<a id=getWhiteBoardDataList5380> </a></h2>
<p></p>
<h3 id="-280">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取会中数据块列表
</code></pre>
<h3 id="-281">请求参数</h3>
<h3 id="-282">返回数据</h3>
<pre><code class="language-javascript">返回数据块数组（数据块为DataBlockObj类型对象）
</code></pre>
<h2 id="getwhiteboardlist0a3ca20id3dgetwhiteboardlist53803e203ca3e">getWhiteBoardList
<a id=getWhiteBoardList5380> </a></h2>
<p></p>
<h3 id="-283">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>获取当前所有白板数组
</code></pre>
<h3 id="-284">请求参数</h3>
<h3 id="-285">返回数据</h3>
<pre><code class="language-javascript">返回白板数组（白板为WhiteBoardView类型）
</code></pre>
<h2 id="ishiddenthumingclosebtn0a3ca20id3dishiddenthumingclosebtn53803e203ca3e">isHiddenThumingCloseBtn
<a id=isHiddenThumingCloseBtn5380> </a></h2>
<p></p>
<h3 id="-286">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>是否显示缩略图的关闭按钮
</code></pre>
<h3 id="-287">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>WhiteBoardView * blockView</td>
<td>是</td>
<td></td>
<td>缩略图视图</td>
</tr>
</tbody>
</table>
<h3 id="-288">返回数据</h3>
<pre><code class="language-javascript">返回BOOL值 （YES:显示缩略图关闭按钮   NO:隐藏缩略图关闭按钮 ）
</code></pre>
<h2 id="ishiddenvncthumingdeletebtn0a3ca20id3dishiddenvncthumingdeletebtn53803e203ca3e">isHiddenVNCThumingDeleteBtn
<a id=isHiddenVNCThumingDeleteBtn5380> </a></h2>
<p></p>
<h3 id="-289">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>是否显示屏幕共享缩略图关闭按钮

//数据类型
typedef enum
{
&nbsp; &nbsp; _DATA_TYPE_NONE&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; = 0,&nbsp; &nbsp; //默认,无类型，平面类型（如Windows端的会议室信息页面）
&nbsp; &nbsp; _DATA_TYPE_WB&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; = 1,&nbsp; &nbsp; //白板
&nbsp; &nbsp; _DATA_TYPE_APPSHARE&nbsp; &nbsp; &nbsp; &nbsp; = 2,&nbsp; &nbsp; //屏幕共享
&nbsp; &nbsp; _DATA_TYPE_WEB&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; = 3,&nbsp; &nbsp; //Web协同浏览
&nbsp; &nbsp; _DATA_TYPE_MEDIASHARE&nbsp; &nbsp; = 4,&nbsp; &nbsp; //媒体共享
&nbsp; &nbsp; _DATA_TYPE_VOTE&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; = 5,&nbsp; &nbsp; //电子投票
&nbsp; &nbsp; _DATA_TYPE_VIDEO&nbsp; &nbsp; &nbsp; &nbsp; = 6,&nbsp; &nbsp; //视频
&nbsp; &nbsp; _DATA_TYPE_ROUND&nbsp; &nbsp; &nbsp; &nbsp; = 7 &nbsp; &nbsp; // 视频轮巡dataID为轮巡ID，userData为轮巡类型（本地 0，广播 1）；
}_DataType;
</code></pre>
<h3 id="-290">请求参数</h3>
<h3 id="-291">返回数据</h3>
<pre><code class="language-javascript">返回BOOL值 （YES:显示屏幕共享缩略图关闭按钮  NO:隐藏屏幕共享缩略图关闭按钮）
</code></pre>
<h2 id="isinmarkingvc0a3ca20id3disinmarkingvc53803e203ca3e">isInMarkingVC
<a id=isInMarkingVC5380> </a></h2>
<p></p>
<h3 id="-292">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p><span class="colour" style="color:rgb(93, 108, 121)"></span></p>
<pre><code>是否在标注页面
</code></pre>
<h3 id="-293">请求参数</h3>
<h3 id="-294">返回数据</h3>
<pre><code class="language-javascript">返回BOOL值 (YES:在标注页面，NO:不在标注页面)
</code></pre>
<h2 id="reloadcurrentpage0a3ca20id3dreloadcurrentpage53803e203ca3e">reloadCurrentPage
<a id=reloadCurrentPage5380> </a></h2>
<p></p>
<h3 id="-295">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p><span class="colour" style="color: rgb(26, 83, 1);">每次激活白板后都需要重绘</span></p>
<h3 id="-296">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>WhiteBoardView currentWBView</td>
<td>是</td>
<td></td>
<td>需要刷新的白板视图对象</td>
</tr>
</tbody>
</table>
<h3 id="-297">返回数据</h3>
<pre><code class="language-javascript">无返回值
</code></pre>
<h2 id="sendwbactiveinfo2remote0a3ca20id3dsendwbactiveinfo2remote53803e203ca3e">sendWBActiveInfo2Remote
<a id=sendWBActiveInfo2Remote5380> </a></h2>
<p></p>
<h3 id="-298">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p><span class="colour" style="color: rgb(26, 83, 1);">发送白板激活消息到白板服务器</span></p>
<h3 id="-299">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>unsigned int</td>
<td>是</td>
<td></td>
<td>白板ID</td>
</tr>
</tbody>
</table>
<h3 id="-300">返回数据</h3>
<pre><code class="language-javascript">ReturnCode   返回错误码，详情请查看ReturnCode定义
</code></pre>
<h2 id="setcurrentpencolor0a3ca20id3dsetcurrentpencolor53803e203ca3e">setCurrentPenColor
<a id=setCurrentPenColor5380> </a></h2>
<p></p>
<h3 id="-301">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>设置当前画笔颜色
</code></pre>
<h3 id="-302">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>UIColor  *color</td>
<td>是</td>
<td></td>
<td>颜色</td>
</tr>
</tbody>
</table>
<h3 id="-303">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="setlocalactivewbobjwithblockobj0a3ca20id3dsetlocalactivewbobjwithblockobj53803e203ca3e">setLocalActiveWBObjWithblockObj
<a id=setLocalActiveWBObjWithblockObj5380> </a></h2>
<p></p>
<h3 id="-304">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p><span class="colour" style="color: rgb(26, 83, 1);">通过白板数据对象激活白板</span></p>
<h3 id="-305">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>DataBlockObj blockObj</td>
<td>是</td>
<td></td>
<td>白板数据块</td>
</tr>
</tbody>
</table>
<h3 id="-306">返回数据</h3>
<pre><code class="language-javascript">返回错误码，详情请查看ReturnCode定义
</code></pre>
<h2 id="setlocaldataactiveobjpos0a3ca20id3dsetlocaldataactiveobjpos53803e203ca3e">setLocalDataActiveObjPos
<a id=setLocalDataActiveObjPos5380> </a></h2>
<p></p>
<h3 id="-307">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p><span class="colour" style="color: rgb(26, 83, 1);">设置本地激活白板的位置,同步布局时需要标记本地位置</span></p>
<h3 id="-308">请求参数</h3>
<h3 id="-309">返回数据</h3>
<pre><code class="language-javascript">返回错误码，详情请查看ReturnCode定义
</code></pre>
<h2 id="setlocaldataactivetype0a3ca20id3dsetlocaldataactivetype53803e203ca3e">setLocalDataActiveType
<a id=setLocalDataActiveType5380> </a></h2>
<p></p>
<h3 id="-310">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p><span class="colour" style="color: rgb(26, 83, 1);">设置本地激活的数据类型</span></p>
<h3 id="-311">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>_DataType dataType</td>
<td>是</td>
<td></td>
<td>数据类型</td>
</tr>
</tbody>
</table>
<h3 id="-312">返回数据</h3>
<pre><code class="language-javascript">ReturnCode   返回错误码，详情请查看ReturnCode定义
</code></pre>
<h2 id="setrotateangle0a3ca20id3dsetrotateangle53803e203ca3e">setRotateAngle
<a id=setRotateAngle5380> </a></h2>
<p></p>
<h3 id="-313">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>设置白板旋转角度
</code></pre>
<h3 id="-314">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>NSInteger   nAngle</td>
<td>是</td>
<td></td>
<td>白板旋转角度</td>
</tr>
</tbody>
</table>
<h3 id="-315">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="setwbmarktool0a3ca20id3dsetwbmarktool53803e203ca3e">setWbMarkTool
<a id=setWbMarkTool5380> </a></h2>
<p></p>
<h3 id="-316">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>设置白板标注工具

WbMarkToolType_None, &nbsp; &nbsp; // 无选中的工具
WbMarkToolType_PenCil, &nbsp; // 画笔
WbMarkToolType_Eraser&nbsp; &nbsp; // 橡皮擦
</code></pre>
<h3 id="-317">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>WBMarkToolType    markToolType</td>
<td>是</td>
<td></td>
<td>标注工具</td>
</tr>
</tbody>
</table>
<h3 id="-318">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="setwhiteboardiscanmark0a3ca20id3dsetwhiteboardiscanmark53803e203ca3e">setWhiteBoardIsCanMark
<a id=setWhiteBoardIsCanMark5380> </a></h2>
<p></p>
<h3 id="-319">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>设置白板是否可以标注
</code></pre>
<h3 id="-320">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>BOOL  isCanMark</td>
<td>是</td>
<td></td>
<td>白板是否可以标注</td>
</tr>
</tbody>
</table>
<h3 id="-321">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="setwhiteboardisthuming0a3ca20id3dsetwhiteboardisthuming53803e203ca3e">setWhiteBoardIsThuming
<a id=setWhiteBoardIsThuming5380> </a></h2>
<p></p>
<h3 id="-322">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>设置数据区域当前是否处于缩略图页面
</code></pre>
<h3 id="-323">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>Bool   isThumbing</td>
<td>是</td>
<td></td>
<td>是否处于缩略图页面</td>
</tr>
</tbody>
</table>
<h3 id="-324">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="startmarkwhiteboard0a3ca20id3dstartmarkwhiteboard53803e203ca3e">startMarkWhiteBoard
<a id=startMarkWhiteBoard5380> </a></h2>
<p></p>
<h3 id="-325">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>开始标注白板
</code></pre>
<h3 id="-326">请求参数</h3>
<h3 id="-327">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="stopmarkwhiteboard0a3ca20id3dstopmarkwhiteboard53803e203ca3e">stopMarkWhiteBoard
<a id=stopMarkWhiteBoard5380> </a></h2>
<p></p>
<h3 id="-328">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>结束白板标注
</code></pre>
<h3 id="-329">请求参数</h3>
<h3 id="-330">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="stopsharewhiteboard0a3ca20id3dstopsharewhiteboard53803e203ca3e">stopShareWhiteBoard
<a id=stopShareWhiteBoard5380> </a></h2>
<p></p>
<h3 id="-331">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>停止共享白板
</code></pre>
<h3 id="-332">请求参数</h3>
<h3 id="-333">返回数据</h3>
<pre><code class="language-javascript">返回ReturnCode状态码
</code></pre>
<h2 id="whiteboardisthuming0a3ca20id3dwhiteboardisthuming53803e203ca3e">whiteBoardIsThuming
<a id=whiteBoardIsThuming5380> </a></h2>
<p></p>
<h3 id="-334">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>是否处于缩略图页面
</code></pre>
<h3 id="-335">请求参数</h3>
<h3 id="-336">返回数据</h3>
<pre><code class="language-javascript">返回BOOL值(YES:处于缩略图页面，NO:不处于缩略图页面)
</code></pre>
<h1 id="roomlistmanager28u4f1au8baeu5217u8868u7ba1u7406u7c7b29">RoomListManager(会议列表管理类)</h1>
<p></p>
<h2 id="addeventlistener0a3ca20id3daddeventlistener53843e203ca3e">addEventListener
<a id=addEventListener5384> </a></h2>
<p></p>
<h3 id="-337">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p>列表监听回调，需要实现EventListenerDelegate 代理</p>
<h3 id="-338">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>id listener</td>
<td>是</td>
<td></td>
<td>监听回调对象</td>
</tr>
</tbody>
</table>
<h3 id="-339">返回数据</h3>
<pre><code class="language-javascript">无返回值
</code></pre>
<h2 id="createinstantmeeting0a3ca20id3dcreateinstantmeeting53843e203ca3e">createInstantMeeting
<a id=createInstantMeeting5384> </a></h2>
<p></p>
<h3 id="-340">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p><span class="colour" style="color: rgb(26, 83, 1);">创建即时会议</span></p>
<h3 id="-341">请求参数</h3>
<p><strong>Headers</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>参数值</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>(NSString *)meetingName</td>
<td></td>
<td>是</td>
<td></td>
<td></td>
</tr>
</tbody>
</table>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>(NSString *)meetingName</td>
<td>是</td>
<td></td>
<td>会议室名称</td>
</tr>
<tr>
<td>(TagMeetingType)meetingType</td>
<td>是</td>
<td></td>
<td>会议类型</td>
</tr>
<tr>
<td>(NSString *)meetingPwd</td>
<td>是</td>
<td></td>
<td>会议密码</td>
</tr>
<tr>
<td>(NSString *)adminPwd</td>
<td>是</td>
<td></td>
<td>管理员密码</td>
</tr>
<tr>
<td>(NSArray *)userList</td>
<td>是</td>
<td></td>
<td>授权用户数组</td>
</tr>
</tbody>
</table>
<h3 id="-342">返回数据</h3>
<pre><code class="language-javascript">返回错误码，详情请查看ReturnCode定义
</code></pre>
<h2 id="querycommonmeeting0a3ca20id3dquerycommonmeeting53843e203ca3e">queryCommonMeeting
<a id=queryCommonMeeting5384> </a></h2>
<p></p>
<h3 id="-343">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p><span class="colour" style="color:rgb(26, 83, 1)">获取固定会议室列表</span></p>
<h3 id="-344">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>(NSString *)searchKey</td>
<td>否</td>
<td></td>
<td>搜索关键字</td>
</tr>
<tr>
<td>(NSInteger)curPage</td>
<td>是</td>
<td></td>
<td>当前页码</td>
</tr>
<tr>
<td>(NSInteger)pageSize</td>
<td>是</td>
<td></td>
<td>页大小</td>
</tr>
</tbody>
</table>
<h3 id="-345">返回数据</h3>
<pre><code class="language-javascript">返回错误码，详情请查看ReturnCode定义
</code></pre>
<h2 id="queryinstantmeeting0a3ca20id3dqueryinstantmeeting53843e203ca3e">queryInstantMeeting
<a id=queryInstantMeeting5384> </a></h2>
<p></p>
<h3 id="-346">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p><span class="colour" style="color: rgb(26, 83, 1);">获取即时会议室列表</span></p>
<h3 id="-347">请求参数</h3>
<h3 id="-348">返回数据</h3>
<pre><code class="language-javascript">返回错误码，详情请查看ReturnCode定义
</code></pre>
<h2 id="queryschedulmeeting0a3ca20id3dqueryschedulmeeting53843e203ca3e">querySchedulMeeting
<a id=querySchedulMeeting5384> </a></h2>
<p></p>
<h3 id="-349">基本信息</h3>


<p><strong>接口描述：</strong></p>
<p><span class="colour" style="color: rgb(26, 83, 1);">获取预约会议室列表</span></p>
<h3 id="-350">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>(NSString *)searchKey</td>
<td>否</td>
<td></td>
<td>搜索关键字</td>
</tr>
<tr>
<td>(NSInteger)nRecentDays</td>
<td>是</td>
<td></td>
<td>最近几天的数据</td>
</tr>
<tr>
<td>(NSInteger)curPage</td>
<td>是</td>
<td></td>
<td>当前页码</td>
</tr>
<tr>
<td>(NSInteger)pageSize</td>
<td>是</td>
<td></td>
<td>页大小</td>
</tr>
</tbody>
</table>
<h3 id="-351">返回数据</h3>
<pre><code class="language-javascript">返回错误码，详情请查看ReturnCode定义
</code></pre>
<h1 id="contactmanager28u901au8bafu5f55u547cu53ebu7ba1u7406u7c7b29">ContactManager(通讯录呼叫管理类)</h1>
<p></p>
<h2 id="acceptrejectinvite0a3ca20id3dacceptrejectinvite53883e203ca3e">acceptRejectInvite
<a id=acceptRejectInvite5388> </a></h2>
<p></p>
<h3 id="-352">基本信息</h3>


<p><strong>接口描述：</strong></p>
<h3 id="-353">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>inviteUserId</td>
<td>是</td>
<td></td>
<td></td>
</tr>
<tr>
<td>inviteId</td>
<td>是</td>
<td></td>
<td></td>
</tr>
<tr>
<td>isAccept</td>
<td>是</td>
<td></td>
<td></td>
</tr>
</tbody>
</table>
<h3 id="-354">返回数据</h3>
<pre><code class="language-javascript">@return BOOL    YES - 成功  NO - 失败
</code></pre>
<h2 id="getonlinedeviceinfo0a3ca20id3dgetonlinedeviceinfo53883e203ca3e">getOnlineDeviceInfo
<a id=getOnlineDeviceInfo5388> </a></h2>
<p></p>
<h3 id="-355">基本信息</h3>


<p><strong>接口描述：</strong></p>
<h3 id="-356">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>userID</td>
<td>是</td>
<td></td>
<td></td>
</tr>
</tbody>
</table>
<h3 id="-357">返回数据</h3>
<pre><code class="language-javascript">@return BOOL    YES - 成功  NO - 失败
</code></pre>
<h2 id="inviteusers0a3ca20id3dinviteusers53883e203ca3e">inviteUsers
<a id=inviteUsers5388> </a></h2>
<p></p>
<h3 id="-358">基本信息</h3>


<p><strong>接口描述：</strong></p>
<h3 id="-359">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>userIdList</td>
<td>是</td>
<td></td>
<td></td>
</tr>
<tr>
<td>inviteDataObj</td>
<td>是</td>
<td></td>
<td></td>
</tr>
</tbody>
</table>
<h3 id="-360">返回数据</h3>
<pre><code class="language-javascript">@return BOOL    YES - 成功  NO - 失败
</code></pre>
<h2 id="issupportinvite0a3ca20id3dissupportinvite53883e203ca3e">isSupportInvite
<a id=isSupportInvite5388> </a></h2>
<p></p>
<h3 id="-361">基本信息</h3>


<p><strong>接口描述：</strong></p>
<h3 id="-362">请求参数</h3>
<h3 id="-363">返回数据</h3>
<pre><code class="language-javascript">@return  返回YES 表示该账号支持通讯录邀请  返回NO则表示不支持通讯录邀请
</code></pre>
<h2 id="querydeptinfo0a3ca20id3dquerydeptinfo53883e203ca3e">queryDeptInfo
<a id=queryDeptInfo5388> </a></h2>
<p></p>
<h3 id="-364">基本信息</h3>


<p><strong>接口描述：</strong></p>
<h3 id="-365">请求参数</h3>
<h3 id="-366">返回数据</h3>
<pre><code class="language-javascript">@return ReturnCode    返回错误码，详情请查看ReturnCode定义
</code></pre>
<h2 id="querydeptuser0a3ca20id3dquerydeptuser53883e203ca3e">queryDeptUser
<a id=queryDeptUser5388> </a></h2>
<p></p>
<h3 id="-367">基本信息</h3>


<p><strong>接口描述：</strong></p>
<h3 id="-368">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>deptID</td>
<td>是</td>
<td>1000001</td>
<td></td>
</tr>
</tbody>
</table>
<h3 id="-369">返回数据</h3>
<pre><code class="language-javascript">@return ReturnCode    返回错误码，详情请查看ReturnCode定义
</code></pre>
<h2 id="queryonlinestatuswithuserlist0a3ca20id3dqueryonlinestatuswithuserlist53883e203ca3e">queryOnlineStatusWithUserList
<a id=queryOnlineStatusWithUserList5388> </a></h2>
<p></p>
<h3 id="-370">基本信息</h3>


<p><strong>接口描述：</strong></p>
<h3 id="-371">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>userList</td>
<td>是</td>
<td></td>
<td></td>
</tr>
</tbody>
</table>
<h3 id="-372">返回数据</h3>
<pre><code class="language-javascript">@return ReturnCode    返回错误码，详情请查看ReturnCode定义
</code></pre>
<h2 id="searchuserwithnickname0a3ca20id3dsearchuserwithnickname53883e203ca3e">searchUserWithNickName
<a id=searchUserWithNickName5388> </a></h2>
<p></p>
<h3 id="-373">基本信息</h3>


<p><strong>接口描述：</strong></p>
<h3 id="-374">请求参数</h3>
<p><strong>Query</strong></p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>是否必须</th>
<th>示例</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>nickName</td>
<td>是</td>
<td></td>
<td></td>
</tr>
</tbody>
</table>
<h3 id="-375">返回数据</h3>
<pre><code class="language-javascript">@return NSArray&lt;OnlineUserViewModel *&gt;    返回用户列表
</code></pre>
<h1 id="managerdelegate">ManagerDelegate</h1>
<p></p>
<h2 id="audiomanagerdelegate0a3ca20id3daudiomanagerdelegate53963e203ca3e">AudioManagerDelegate
<a id=AudioManagerDelegate5396> </a></h2>
<p></p>
<h3 id="-376">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>/// 音频发生改变的回调
/// @param userID 发生改变的用户ID
- (void)onUserAudioStateChange:(NSInteger)userID;
</code></pre>
<h3 id="-377">请求参数</h3>
<h2 id="chatmanagerdelegte0a3ca20id3dchatmanagerdelegte53963e203ca3e">ChatManagerDelegte
<a id=ChatManagerDelegte5396> </a></h2>
<p></p>
<h3 id="-378">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>/// 接收到的聊天信息
/// @param info 聊天信息
- (void)onReceiveChatMessage:(ChatMessageInfo *)info;
</code></pre>
<h3 id="-379">请求参数</h3>
<h2 id="contactmanagerdelegate0a3ca20id3dcontactmanagerdelegate53963e203ca3e">ContactManagerDelegate
<a id=ContactManagerDelegate5396> </a></h2>
<p></p>
<h3 id="-380">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>/// 获取组织架构的回调
/// @param obj 组织架构列表信息
- (void)onQueryDept:(tagDeptListObj *)obj;
/// 获取某个组织下的所有人员和子组织信息列表
/// @param userList 组织下所有成员
/// @param children 子组织列表
/// @param allUserList 所有用户
- (void)onQueryDeptUser:(NSMutableArray &lt;tagOnlineUserInfoObj *&gt; *)userList withChildren:(NSMutableArray &lt;DeptNodeViewModel *&gt; *)children withAllUserList:(NSMutableArray &lt;OnlineUserViewModel *&gt; *)allUserList;
/// 成员在线状态改变的回调
/// @param model 改变的成员model
- (void)OnUserOnlineStateChange:(OnlineUserViewModel *)model;
</code></pre>
<h3 id="-381">请求参数</h3>
<h2 id="imeetingmanagerdelegate0a3ca20id3dimeetingmanagerdelegate53963e203ca3e">IMeetingManagerDelegate
<a id=IMeetingManagerDelegate5396> </a></h2>
<p></p>
<h3 id="-382">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>///结束会议的回调
- (void)onEndMeeting;
/// Session重连状态的回调
/// @param state 重连状态
- (void)onSessionReconnect:(ReconnectState)state;
/// 同步布局
/// @param event&nbsp; 同步的布局信息
- (void)onRemoteLayout:(LayoutEvent *)event;
</code></pre>
<h3 id="-383">请求参数</h3>
<h2 id="iusermanagerdelegate0a3ca20id3diusermanagerdelegate53963e203ca3e">IUserManagerDelegate
<a id=IUserManagerDelegate5396> </a></h2>
<p></p>
<h3 id="-384">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>/// 参会人申请管理
/// @param code 申请返回的结果
- (void)onUserApplyManager:(ReturnCode)code;
/// 用户上线的回调
/// @param userInfo&nbsp; 上线参会人的信息
- (void)onUserOnline:(BaseUserInfo *)userInfo;
/// 用户进入会议室的回调
/// @param userInfo&nbsp; 进入会议室参会人的信息
- (void)onUserEnter:(BaseUserInfo *)userInfo;
/// 用户离开会议室
/// @param userID&nbsp; 离开参会人的用户ID
/// @param userInfo&nbsp; 离开参会人的用户信息
- (void)onUserLeave:(NSInteger)userID withUserInfo:(BaseUserInfo *)userInfo;
/// 用户信息更改
/// @param userInfo&nbsp; 被更改信息的参会人
- (void)onUserInfoUpdate:(BaseUserInfo *)userInfo;
/// 用户权限更改
/// @param userInfo 被修改权限的用户
- (void)onUserRightChanged:(NSInteger)userID;
/// 用户是否是主持人的状态更改
/// @param userID&nbsp; 被修改用户信息的用户ID
/// @param state 用户的状态
- (void)onUserDataStateChange:(NSInteger)userID withUserState:(UserState)state;
/// 被请出会议室
/// @param userInfo&nbsp; 被剔除参会人的信息
- (void)onUserKicked:(BaseUserInfo *)userInfo;
- (void)onUserAVInfoState:(NSInteger)userID;
</code></pre>
<h3 id="-385">请求参数</h3>
<h2 id="loginmanagerdelegate0a3ca20id3dloginmanagerdelegate53963e203ca3e">LoginManagerDelegate
<a id=LoginManagerDelegate5396> </a></h2>
<p></p>
<h3 id="-386">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>/// 登录、入会结果的回调
/// @param code 登录结果的错误码
/// @param message 登录结果的错误信息
/// @param type 登录的类型
- (void)onUserloginEventCallback:(ReturnCode)code withErrorMessage:(NSString *)message withLoginType:(LoginType)type;
</code></pre>
<h3 id="-387">请求参数</h3>
<h2 id="permissionmanagerdelegate0a3ca20id3dpermissionmanagerdelegate53963e203ca3e">PermissionManagerDelegate
<a id=PermissionManagerDelegate5396> </a></h2>
<p></p>
<h3 id="-388">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>/// 成员组权限发生改变的回调
/// @param type 改变的权限类型
- (void)onUserGroupPermissionChanged:(kUserGroupPermissionType)type;
/// 会议室权限发生改变的回调
/// @param type 改变权限的类型
- (void)onRoomPermissionChanged:(kRoomPermissionType)type;
/// 参会人权限改变的回调
/// @param type 改变的权限类型
/// @param userID 改变权限参会人的ID
- (void)onUserPermissionChanged:(kUserPermissionType)type withUserID:(NSInteger)userID;
</code></pre>
<h3 id="-389">请求参数</h3>
<h2 id="roomlistmanagerdelegate0a3ca20id3droomlistmanagerdelegate53963e203ca3e">RoomListManagerDelegate
<a id=RoomListManagerDelegate5396> </a></h2>
<p></p>
<h3 id="-390">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>/// 查询云会议室的结果
/// @param isSuccess 是否查询成功
/// @param meetingList 查询到的会议列表
/// @param isLoadCloudMeetingAll 是否查询完毕
/// @param msg 查询失败后的错误信息
- (void)onQueryCommonMeeting:(BOOL)isSuccess withMeetingList:(NSArray &lt;TagRoomInfoObj *&gt; *)meetingList isLoadCloudMeetingAll:(BOOL)isLoadCloudMeetingAll witherrMessage:(NSString *)msg;
/// 查询预约会议室的结果
/// @param isSuccess 是否查询成功
/// @param meetingList 查询到的会议列表
/// @param isLoadSchedulMeetingAll 是否查询完毕
/// @param msg 查询失败后的错误信息
- (void)onQuerySchedulMeeting:(BOOL)isSuccess withMeetingList:(NSArray &lt;tagScheduleInfoObj *&gt; *)meetingList isLoadSchedulMeetingAll:(BOOL)isLoadSchedulMeetingAll witherrMessage:(NSString *)msg;
/// 查询即时会议的结果
/// @param meetingList 即时会议列表
/// @param isLoadInstantMeetingAll 是否查询完毕
- (void)onQueryInstantMeeting:(NSArray &lt;tagInstantRoomInfoObj *&gt; *)meetingList isLoadInstantMeetingAll:(BOOL)isLoadInstantMeetingAll;
/// 创建即时会议的结果
/// @param isSuccess 是否创建成功
/// @param code 创建失败的错误信息
/// @param roomInfo 创建完成的会议室
- (void)onCreateInstantMeeting:(BOOL)isSuccess withErrCode:(NSInteger)code withTagRoomInfoObj:(TagRoomInfoObj *)roomInfo;
</code></pre>
<h3 id="-391">请求参数</h3>
<h2 id="screensharemanagerdelegate0a3ca20id3dscreensharemanagerdelegate53963e203ca3e">ScreenShareManagerDelegate
<a id=ScreenShareManagerDelegate5396> </a></h2>
<p></p>
<h3 id="-392">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>/// 共享屏幕状态发生改变
/// @param state 状态
/// @param userID 发生改变的参会人id
- (void)onScreenShareState:(ScreenShareState)state withUserID:(NSInteger)userID;
</code></pre>
<h3 id="-393">请求参数</h3>
<h2 id="sdkmanagerdelegate0a3ca20id3dsdkmanagerdelegate53963e203ca3e">SdkManagerDelegate
<a id=SdkManagerDelegate5396> </a></h2>
<p></p>
<h3 id="-394">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>/// 设置服务器信息后的结果
/// @param isSuccess 是否设置成功
- (void)onSetServerResult:(BOOL)isSuccess;
/// 接收到其他人呼叫入会的回调
/// @param userID 呼叫人的userID
/// @param inviteId 呼叫ID
/// @param obj 呼叫的信息
- (void)onInviteIncome:(NSInteger)userID withinviteId:(NSInteger)inviteId withInviteDataObj:(InviteDataObj *)obj;
/// 拒接邀请后的回调
/// @param userID 拒接的成员ID
- (void)OnAbandonInvite:(NSInteger)userID;
</code></pre>
<h3 id="-395">请求参数</h3>
<h2 id="videomanagerdelegate0a3ca20id3dvideomanagerdelegate53963e203ca3e">VideoManagerDelegate
<a id=VideoManagerDelegate5396> </a></h2>
<p></p>
<h3 id="-396">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>/// 参会人视频状态改变的回调
/// @param userID 发生改变的参会人的用户ID
/// @param videoID 发生改变的视频ID
/// @param state 改变后的状态
- (void)onUserVideoStateChange:(NSInteger)userID withVideoID:(NSInteger)videoID withVideoState:(VideoState)state;
</code></pre>
<h3 id="-397">请求参数</h3>
<h2 id="whiteboardmanagerdelegate0a3ca20id3dwhiteboardmanagerdelegate53963e203ca3e">WhiteBoardManagerDelegate
<a id=WhiteBoardManagerDelegate5396> </a></h2>
<p></p>
<h3 id="-398">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>- (**void**)OnInitWB:(NSInteger)wbID withUserID:(NSInteger)wbUserId withDataBlack:(DataBlockObj *)datablock;
/// 接收共享白板时的错误
/// @param message 错误信息
/// @param type 错误类型
/// @param msgType 错误信息展示的方式
- (void)onReceiveWhiteBoardErrorMessage:(NSString *)message withCodeType:(WBErrorCodeType)type withMessageType:(WhiteBoardMessageType)msgType;
///白板数量改变
- (void)onWhiteBoardCountChange;
///放弃主持人并且无权限关闭自己创建的共享
- (void)onDataBlockCountOrThumingChange;
/// 被PC取消权限时需要关闭权限
- (void)onCancleWBRight;
/// 刷新当前活跃的白板
/// @param dataID 要刷新白板的ID
- (void)onRefreshWBViewByActiveWB:(NSInteger)dataID;
/// 移除单个的白板
/// @param dataID 要移除白板的ID
- (void)onRemoveSingleWBView:(NSInteger)dataID;
/// 刷新白板的标签昵称
/// @param title 刷新后的标签
- (void)onUpdateWBTitle:(NSString *)title;
</code></pre>
<h3 id="-399">请求参数</h3>
<h1 id="u5173u952eu7c7bu578bu5b9au4e49">关键类型定义</h1>
<p></p>
<h2 id="errcode0a3ca20id3derrcode53923e203ca3e">ErrCode
<a id=ErrCode5392> </a></h2>
<p></p>
<h3 id="-400">基本信息</h3>


<p><strong>接口描述：</strong></p>
<table>
<thead>
<tr>
<th>错误码名</th>
<th>错误码值</th>
<th>错误描述</th>
<th>建议处理</th>
</tr>
</thead>
<tbody>
<tr>
<td>ERR_UNKNOW</td>
<td></td>
<td>未知错误</td>
<td></td>
</tr>
<tr>
<td>ERR_SUCCESS</td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">成功</span></td>
<td></td>
</tr>
<tr>
<td>ERR_CLIENTID_ERROR</td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">ClientID或ClientSecret信息错误</span></td>
<td></td>
</tr>
<tr>
<td>ERR_NETWORK</td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">网络错误</span></td>
<td></td>
</tr>
<tr>
<td>ERR_CONNECT_FAILED</td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">网络连接失败</span></td>
<td></td>
</tr>
<tr>
<td>ERR_INVALID_PARAM</td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">参数异常，用于登录参数验证</span></td>
<td></td>
</tr>
<tr>
<td>ERR_IN_LOGINING</td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">正在登录会议室，防止多次调用登录会议室</span></td>
<td></td>
</tr>
<tr>
<td>ERR_LOGIN_TIMEOUT</td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">登录超时</span></td>
<td></td>
</tr>
<tr>
<td>ERR_ACCOUT_PWD</td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">账号名或密码错误</span></td>
<td></td>
</tr>
<tr>
<td>ERR_LOGIN_SUCCESS</td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">登录成功</span></td>
<td></td>
</tr>
<tr>
<td>ERR_LOGIN_FAILED</td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">登录失败</span></td>
<td></td>
</tr>
<tr>
<td>ERR_LOGIN_CANCELED</td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">登录取消</span></td>
<td></td>
</tr>
<tr>
<td>ERR_SERVER_UNAUTHORIZED</td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">服务器未授权</span></td>
<td></td>
</tr>
<tr>
<td>ERR_ROOM_LOCKED</td>
<td></td>
<td>会议室已锁定</td>
<td></td>
</tr>
<tr>
<td>ERR_USER_REFUSED_ENTER</td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">锁定会议室后，用户被拒绝进入</span></td>
<td></td>
</tr>
<tr>
<td>ERR_USER_FORBID_ENTER</td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">锁定会议室后，用户被拉黑</span></td>
<td></td>
</tr>
<tr>
<td>ERR_NOT_PERMISSION_ENTER</td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">用户无权进入会议室</span></td>
<td></td>
</tr>
<tr>
<td>ERR_ROOM_NOT_FOUND</td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">会议室未找到</span></td>
<td></td>
</tr>
<tr>
<td>ERR_ROOM_FULL</td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">会议室已满</span></td>
<td></td>
</tr>
<tr>
<td>ERR_HAS_LOGINED</td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">用户重复进入同一会议室</span></td>
<td></td>
</tr>
<tr>
<td>ERR_PASS_CONNECTING</td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">PASS重连</span></td>
<td></td>
</tr>
<tr>
<td>ERR_VERIFY_ROOM</td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">会议室校验错误</span></td>
<td></td>
</tr>
<tr>
<td><span class="colour" style="color:rgba(0, 0, 0, 0.85098)">ERR_ROOM_PWD</span></td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">会议室密码错误</span></td>
<td></td>
</tr>
<tr>
<td><span class="colour" style="color:rgba(0, 0, 0, 0.85098)">ERR_NO_PERMISSION</span></td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">没有权限</span></td>
<td></td>
</tr>
<tr>
<td><span class="colour" style="color:rgba(0, 0, 0, 0.85098)">ERR_NEED_APPLY_PERMISSION</span></td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">音视频需要申请权限</span></td>
<td></td>
</tr>
<tr>
<td><span class="colour" style="color:rgba(0, 0, 0, 0.85098)">ERR_APPLY_SUCCESS</span></td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">申请成功</span></td>
<td></td>
</tr>
<tr>
<td><span class="colour" style="color:rgba(0, 0, 0, 0.85098)">ERR_APPLY_FAILED</span></td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">申请失败</span></td>
<td></td>
</tr>
<tr>
<td><span class="colour" style="color:rgba(0, 0, 0, 0.85098)">ERR_INIT_FAILED</span></td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">SDK 未初始化</span></td>
<td></td>
</tr>
<tr>
<td><span class="colour" style="color:rgba(0, 0, 0, 0.85098)">ERR_ORGANIZATION_POINT_FULL</span></td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">组织授权点数已满</span></td>
<td></td>
</tr>
<tr>
<td><span class="colour" style="color:rgba(0, 0, 0, 0.85098)">ERR_ONLY_ACCOUNT_LOGIN</span></td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">只允许以用户名密码方式登录</span></td>
<td></td>
</tr>
<tr>
<td><span class="colour" style="color:rgba(0, 0, 0, 0.85098)">ERR_APPLY_NOTPERMISSION</span></td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">不允许申请管理员</span></td>
<td></td>
</tr>
<tr>
<td><span class="colour" style="color:rgba(0, 0, 0, 0.85098)">ERR_FSP_LOGIN_FAILED</span></td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">FSP登录失败</span></td>
<td></td>
</tr>
<tr>
<td><span class="colour" style="color:rgba(0, 0, 0, 0.85098)">ERR_USER_NOT_AUTHOR</span></td>
<td></td>
<td><span class="colour" style="color:rgb(93, 108, 121)">会议室未授权该用户</span></td>
<td></td>
</tr>
</tbody>
</table>
<h3 id="-401">请求参数</h3>
<h3 id="-402">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="baseuserinfo0a3ca20id3dbaseuserinfo53923e203ca3e">BaseUserInfo
<a id=BaseUserInfo5392> </a></h2>
<p></p>
<h3 id="-403">基本信息</h3>


<p><strong>接口描述：</strong></p>
<pre><code>
@interface BaseUserInfo : NSObject
//初始化
+ (instancetype)baseUserWithUserInfo:(id)userInfo;
//用户id
-(unsigned long)userId;
//用户名
-(NSString*)userName;
//用户昵称
-(NSString*)nickName;
// 获取用户视频列表
- (NSDictionary *)videoChannelMap;
- (NSArray&lt;HstVideoChannelInfo*&gt;*)getVideoChannelInfos;
//dev id
- (int) nDefVideoDevID;
//音频SourceID
-(unsigned long) audioSourceID;
//音频状态
-(AudioState) audioState;
//音频id
-(unsigned int) audioId;
// 判断用户是否是参会人
- (BOOL)isListener;
// 判断用户是否是主持人
- (BOOL)isHost;
//是否正在申请主持人中
- (BOOL)isApplyingHost;
// 判断是否是本地用户
- (BOOL)isLocalUser;
// 判断用户是否打开视频
- (BOOL)isVideoOpen;
///是否正在申请广播视频
- (BOOL)isVideoStateWaiting;
// 获取视频广播状态
- (VideoState)videoState;
//获取主讲状态
-(UserState)userDataState;
//是否是管理员
-(BOOL)isManager;
///本地录制开启状态&lt;!--用户录制状态 0 未录制&nbsp; 1 录制中--&gt;
-(unsigned char)localRecordState;
//终端类型
-(SDKTERMINALTYPE)terminaltype;
// 判断用户是否有麦克风权限
-(BOOL)isHaveMicrophone;
// 判断用户是否有摄像头权限
-(BOOL)isHaveCamera;
@end
</code></pre>
<h3 id="-404">请求参数</h3>
<h3 id="-405">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="kuserpermissiontype0a3ca20id3dkuserpermissiontype53923e203ca3e">kUserPermissionType
<a id=kUserPermissionType5392> </a></h2>
<p></p>
<h3 id="-406">基本信息</h3>


<p><strong>接口描述：</strong></p>
<table>
<thead>
<tr>
<th>用户权限类型名称</th>
<th>权限值</th>
<th>用户权限描述</th>
<th>建议处理</th>
</tr>
</thead>
<tbody>
<tr>
<td>kUserPermissionTypeAudioEnable</td>
<td></td>
<td>拥有音频功能</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeBroadCastOwnAudio</td>
<td></td>
<td>广播自己的音频权限</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeStopBroadCastOwnAudio</td>
<td></td>
<td>关闭自己的音频权限</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeBroadCastOthersAudio</td>
<td></td>
<td>广播他人的音频权限</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeStopBroadCastOthersAudio</td>
<td></td>
<td>关闭他人的音频权限</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeBroadcastSpecifiedOtherAudio,</td>
<td></td>
<td>广播他人音频,需要判断otherUser是否有被广播的权限</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeVideoEnable</td>
<td></td>
<td>拥有视频功能</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeBroadCastOwnVideo</td>
<td></td>
<td>广播自己的视频权限</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeStopBroadCastOwnVideo</td>
<td></td>
<td>关闭自己的视频权限</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeBroadCastOthersVideo</td>
<td></td>
<td>广播他人的视频权限</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeStopBroadCastOthersVideo</td>
<td></td>
<td>关闭他人的视频权限</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeBroadcastSpecifiedOtherVideo</td>
<td></td>
<td>广播他人音频,需要判断otherUser是否有被广播的权限</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypePublicChat</td>
<td></td>
<td>群聊权限</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeCloseMeeting</td>
<td></td>
<td>关闭会议室权限</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeApplyAdmin</td>
<td></td>
<td>申请成为管理员权限</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeViewUserInfo</td>
<td></td>
<td>显示自己的“查看个人信息”选项的权限</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeModifyUserInfo</td>
<td></td>
<td>显示自己的“修改个人信息”选项的权限</td>
<td></td>
</tr>
<tr>
<td>kPermissionTypeModifyOtherUserInfo</td>
<td></td>
<td>显示他人的“修改个人信息”选项的权限</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeDisableModifySelfUserInfo</td>
<td></td>
<td>禁止修改自己的信息的权限</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeApplyPresenter</td>
<td></td>
<td>申请成为/放弃主讲人的权限</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeKickUser,</td>
<td></td>
<td>踢人出会议室的权限</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeBroadcastLayout,</td>
<td></td>
<td>用户是否有同步布局权限</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeConfigOtherPresenter</td>
<td></td>
<td>授予/审批他人成为主讲人</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeCreateMeetingMute</td>
<td></td>
<td>全场静音权限</td>
<td><br data-tomark-pass=""></td>
</tr>
<tr>
<td>kUserPermissionTypeWhiteBoardSave</td>
<td></td>
<td>保存白板权限</td>
<td></td>
</tr>
<tr>
<td>kUserPermissionTypeWhiteBoardShare</td>
<td></td>
<td>白板共享权限~~~~</td>
<td></td>
</tr>
</tbody>
</table>
<h3 id="-407">请求参数</h3>
<h3 id="-408">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>
<h2 id="kroompermissiontype0a3ca20id3dkroompermissiontype53923e203ca3e">kRoomPermissionType
<a id=kRoomPermissionType5392> </a></h2>
<p></p>
<h3 id="-409">基本信息</h3>


<p><strong>接口描述：</strong></p>
<table>
<thead>
<tr>
<th>房间权限类型名称</th>
<th>权限值</th>
<th>房间权限类型名称</th>
<th>建议处理</th>
</tr>
</thead>
<tbody>
<tr>
<td><span class="colour" style="color:rgba(0, 0, 0, 0.85)">kRoomPermissionTypeRoomEnablePublicChat</span></td>
<td></td>
<td>房间群聊权限</td>
<td></td>
</tr>
</tbody>
</table>
<h3 id="-410">请求参数</h3>
<h3 id="-411">返回数据</h3>
<pre><code class="language-javascript">返回值：无
</code></pre>

      <footer class="m-footer">
        <p>Build by <a href="https://ymfe.org/">YMFE</a>.</p>
      </footer>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="css" scoped src="./index.css"></style>
<style scoped>

 .table-of-contents {
    position: absolute;
    height: calc(100% - 50px);
    top: 50px;
    left: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    width: 375px;
}
</style>