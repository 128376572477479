<template>
  <div id="serverapi">
    <div :class="['left-nav', showLeftNav ? 'show' : '']">
    
      <div class="nav-select">
        <div v-for="(item, i) in menuList" :key="i" class="select-option">
          <!-- <div class="select-title"><img :src="item.icon" alt="" />{{ item.title }}</div> -->
          <ul v-for="(onitem, index) in item.content" :key="index" class="menu">
            <li
              @click="selectOption(onitem, item)"
              :class="selectItem === onitem ? 'select-main select-active' : 'select-main'"
            >
              {{ onitem }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  
    <div class="doc-container">
          <Showdoc v-if="title === '说明文档'" />
          <Fmapi v-if="title === '接口文档'" />
    </div>
  </div>
</template>

<script>
import { Showdoc, Fmapi} from './components'
export default {
  components: {
    Showdoc,
    Fmapi,
   
  },
  data() {
    return {
      title:'说明文档',
      selectTitle: '',
      selectItem: '',
      menuList: [
        // {
        //   title: '测试列表',
        //   icon: require('../../images/paas/ksrmicon.png'),
        //   content: ['准备工作', '运行Demo'],
        // },
        {
          title: '测试标题',
          icon: require('../../images/paas/hxgnicon.png'),
          content: ['说明文档', '接口文档', ],
        },
      ],
    };
  },
   
  computed: {
    showLeftNav() {
      return this.$store.state.showLeftNav;
    },
  },
  mounted() {
    this.selectItem = this.menuList[0].content[0];
    this.selectTitle = this.menuList[0].title;
  },
  methods: {
    selectOption(v, k) {
      // console.log(v)
       this.title = v;
       this.selectItem = v;
       this.selectTitle = k.title;
    },
  
  },
};
</script>

<style lang="less" scoped>
// @import '../../css/document.css';
// @import '../../../node_modules/prismjs/themes/prism.css';
// @import '../../css/loading.less';
.left-nav.show {
  display: block;
  left: 0;
}
.show {
  width: 30% !important;
}
#serverapi {
  max-width: 13.66rem;
  margin: 0 auto;
  text-align: left;
  min-height: 94vh;
  background: #fff;
  padding-top: 0.25rem;
  font-size: 0.13rem;
  > div {
    display: inline-block;
    vertical-align: top;
  }
  .left-nav {
    width: 15%;
    max-width: 2.07rem;
    .nav-title {
      background: url('../../images/paas/leftzz.png') no-repeat;
      height: 0.8rem;
      text-align: center;
      line-height: 0.8rem;
      /deep/.ivu-dropdown {
        width: 1.6rem;
        font-weight: 700;
        .ivu-select-dropdown {
          text-align: left;
        }
      }
    }
    .nav-select {
      background-color: #fbfafd;
      padding-bottom: 0.2rem;
      .select-option:not(:first-child) {
        margin-top: 0.1rem;
      }
      .select-title {
        font-size: 0.22rem;
        padding: 0.08rem 0 0.1rem;

        font-weight: 400;
        color: #1c1d36;
        cursor: default;
        text-indent: 0.2rem;
      }
      .select-main {
        font-size: 0.18rem;
        padding: 0.1rem 0;
        font-weight: 300;
        color: #2d2e53;
        cursor: pointer;
        text-indent: 0.6rem;
      }
      .select-main:hover {
        color: #6552c8;
      }
      .select-active {
        background: #e4e1f8;
        border-right: 4px solid #9774c7;
        color: #6552c8;
      }
      img {
        display: inline-block;
        height: 0.22rem;
        margin-right: 0.1rem;
        margin-bottom: 0.02rem;
      }
    }
  }

}

.doc-container {
  width: 82%;
  max-width: 11.04rem;
  height: 93vh;
  // border-left: 1px solid rgba(0, 0, 0, 0.2);
  // border-right: 1px solid rgba(0, 0, 0, 0.2);
}


@media screen and (max-width: 576px) {

  .doc-container {
    width: 100%;
    margin-top: 0.1rem;
    border: 0;
  }

  .left-nav {
    position: fixed;
    width: 3rem;
    max-width: 3rem;
    border-right: 0.01rem solid rgba(233, 233, 233, 1);
    background: #ffffff;
    top: 0.56rem;
    left: -8rem;
    bottom: 0;
    transition: all 0.3s ease-in;
    z-index: 90;
  }

  .left-nav.show {
    display: block;
    left: 0;
  }

  
}
</style>
