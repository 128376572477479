<template>
  <div id="app">
    <NavHome v-if="!isDeveloperModel" />
    <NavDeveloper v-else />

    <div class="app-container">
      <router-view />
    </div>
    <FooterBase v-if="!isDeveloperModel && false" />
  </div>
</template>

<script>
import NavHome from './components/NavHome';
import NavDeveloper from './components/NavDeveloper';
import FooterBase from './components/FooterBase';

export default {
  components: { NavHome, NavDeveloper, FooterBase },

  computed: {
    isDeveloperModel() {
      return this.$store.state.isDeveloperModel;
    },
  },

  mounted() {
    if (document.body.clientWidth > 576) {
      this.$store.commit('changeMobile', false);
    } else {
      this.$store.commit('changeMobile', true);
    }
  },
};
</script>

<style>
#app {
  font-family: SourceHanSansCN-Medium, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
  text-align: center;
}

.app-container {
  margin-top: 0.56rem;
}
</style>
