<template>
  <div id="main">
    <div class="nav-title">
      <Dropdown @on-click="switchTitle">
        <a href="javascript:void(0)">
          {{ title }}
          <Icon type="ios-arrow-down"></Icon>
        </a>
        <DropdownMenu slot="list">
          <DropdownItem name="Windows">
            Windows
          </DropdownItem>
          <DropdownItem name="Android" divided>
            Android
          </DropdownItem>
          <DropdownItem name="iOS" divided>
            iOS
          </DropdownItem>
          <DropdownItem name="Web" divided>
            Web
          </DropdownItem>
          <DropdownItem name="小程序" divided>
            小程序
          </DropdownItem>
          <DropdownItem name="国产化" divided>
            国产化
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>

    <Windows v-if="title === 'Windows'" />
    <Android v-if="title === 'Android'" />
    <iOS v-if="title === 'iOS'" />
    <web-sdk v-if="title === 'Web'" />
    <Xcx v-if="title === '小程序'" />
    <Gch v-if="title === '国产化'" />
  </div>
</template>

<script>
import { Windows, Android, iOS, WebSdk, Xcx, Gch} from './components'
export default {
  components: {
    iOS,
    Windows,
    Android,
    WebSdk,
    Xcx,
    Gch
  },
  data() {
    return {
      title: 'Windows'
    }
  },
  methods: {
    switchTitle(data) {
      this.title = data
    }
  }
}
</script>

<style lang="less" scoped>
.nav-title {
  font-size: 0.4rem;
  font-weight: 700;
  color: black;
  font-weight: bold;
  position: absolute;
  top: 30px;
  left: 60px;
  z-index: 9999;
}
#main {
  padding-top: 50px;
  max-width: 13.66rem;
  margin: 0 auto;
  text-align: left;
  min-height: 94vh;
  height: calc(100vh - 120px);
  background: #fff;
  position: relative;
  font-size: 0.13rem;
}
</style>
