import { render, staticRenderFns } from "./Yapi.vue?vue&type=template&id=5073d222&scoped=true"
import script from "./Yapi.vue?vue&type=script&lang=js"
export * from "./Yapi.vue?vue&type=script&lang=js"
import style0 from "./Yapi.vue?vue&type=style&index=0&id=5073d222&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5073d222",
  null
  
)

export default component.exports