<template>
  <!-- eslint-disable -->
  <div class="base-footer">
    <Row
      type="flex"
      justify="space-around"
      align="top"
      class-name="link-container"
    >
      <Col
        :lg="4"
        :xs="0"
      >
      <div class="title"><img
          src="../images/nav/logo-d.png"
          alt="logo"
        ></div>
      <div class="content">
        <span class="item"><span class="icon"><img
              src="../images/footer/phone.svg"
              alt="phone"
            ></span>0755-83885517</span>
        <span class="item"><span class="icon"><img
              src="../images/footer/qq.svg"
              alt="qq"
            ></span>783541706 (开发者群)</span>
        <span class="item"><span class="icon"><img
              src="../images/footer/mail.svg"
              alt="mail"
            ></span>paas@hst.com</span>
      </div>
      </Col>
      <Col
        :lg="4"
        :xs="0"
        class="pl7"
      >
      <div class="title">产品中心</div>
      <div
        @click="gotoDoc($event)"
        class="content por"
      >
        <span
          data-production="audio"
          class="footer-item"
        >音频通信</span>
        <span
          data-production="video"
          class="footer-item"
        >视频通信</span>
        <span
          data-production="screen_share"
          class="footer-item"
        >屏幕共享</span>
        <span
          data-production="tmsg"
          class="footer-item"
        >信令通道</span>
        <!-- <span data-production="rec" class="item">云录制</span> -->
        <span
          data-production="record"
          class="footer-item"
        >云录制</span>
        <span
          data-production="online"
          class="footer-item"
        >在线</span>
      </div>
      </Col>
      <Col
        :lg="4"
        :xs="0"
        class="pl7"
      >
      <div class="title">开发者</div>
      <div class="content por">
        <span class="footer-item">
          <router-link to="/developer/platform">平台介绍</router-link>
        </span>
        <span class="footer-item">
          <router-link to="/developer/document">产品文档</router-link>
        </span>
        <span class="footer-item">
          <router-link to="/price">价格详情</router-link>
        </span>
        <span class="footer-item">
          <router-link to="/developer/download">下载</router-link>
        </span>
        <span class="footer-item">
          <router-link to="/developer/code">代码</router-link>
        </span>
      </div>
      </Col>
      <Col
        :lg="4"
        :xs="0"
        class="pl7"
      >
      <div class="title">友情链接</div>
      <div class="content">
        <span class="footer-item"><a
            href="https://www.comix.com.cn"
            target="_blank"
          >齐心集团</a></span>
        <span class="footer-item"><a
            href="https://www.hst.com"
            target="_blank"
          >好视通</a></span>
      </div>
      </Col>
      <Col
        :lg="4"
        :xs="24"
        class="pl7"
      >
      <div class="title">了解我们</div>
      <div class="content">
        <span class="item"><img
            class="qrcode"
            src="../images/footer/qrcode.jpg"
            alt="二维码"
          ></span>
        <span class="qr-p">微信服务号</span>
      </div>
      </Col>
    </Row>
    <div class="copyright">
      <!-- <img
        src="../images/footer/footer-icon.png"
        alt="工商网监"
        class="icon"
      > -->
      <p class="info">Copyright &copy; 2013-{{thisYear}} hst.com All Rights Reserved 深圳银澎云计算有限公司 版权所有 粤ICP备12013956号 粤公网安备44030502003560号</p>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      thisYear: 2019
    };
  },
  mounted() {
    this.thisYear = this.getThisYear();
  },
  methods: {
    getThisYear() {
      return new Date().getFullYear();
    },

    gotoDoc(e) {
      let production = e.target.dataset.production;
      let title = "";
      switch (production) {
        case "audio":
          title = "音频通信";
          break;
        case "video":
          title = "视频通信";
          break;
        case "screen_share":
          title = "屏幕共享";
          break;
        case "tmsg":
          title = "信令通道";
          break;
        case "online":
          title = "在线";
          break;
        case "record":
          title = "云录制";
          break;
        default:
          break;
      }
      this.$router.push({
        name: "document",
        query: { production: production },
        params: {
          title: title
        }
      });
    }
  }
};
</script>

<style scoped>
.base-footer {
  width: 100%;
  color: #ffffff;
  background: #393b4f;
}

.link-container {
  width: 12rem;
  margin: 0 auto;
  text-align: left;
  /* padding: 0 4.45rem; */
  min-height: 2.41rem;
  font-family: "SourceHanSansCN-Light";
}

.title {
  margin-top: 0.35rem;
  margin-bottom: 0.36rem;
  font-size: 0.18rem;
  font-weight: 300;
}

.title img {
  display: inline-block;
  max-width: 1.88rem;
  /* height: 0.33rem; */
  /* margin-top: -0.06rem; */
}

.item a,
.item {
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
  display: block;
  font-weight: 300;
  font-size: 0.14rem;
  margin-bottom: 0.2rem;
}

.footer-item a,
.footer-item {
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
  display: block;
  font-weight: 300;
  font-size: 0.14rem;
  margin-bottom: 0.14rem;
}

/* .por {
  position: relative;
}

.por::after {
  position: absolute;
  content: "";
  display: inline-block;
  background: #65677b;
  width: 0.02rem;
  height: 0.88rem;
  right: -0.22rem;
  top: 0;
} */

.pl7 {
  padding-left: 0.4rem;
}

.item .icon {
  display: inline-block;
  vertical-align: top;
  margin-right: 0.14rem;
}

.qrcode {
  display: inline-block;
  max-width: 1.06rem;
}

.qr-p {
  position: relative;
  font-size: 0.14rem;
  top: -0.25rem;
  left: 0.17rem;
}

.copyright {
  padding: 0.1rem 0;
  min-height: 0.5rem;
  background: #232326;
}

.copyright .icon {
  vertical-align: middle;
  display: inline-block;
  height: 0.64rem;
  width: 0.56rem;
}

.copyright .info {
  vertical-align: middle;
  display: inline-block;
  line-height: 0.25rem;
  width: 63%;
  font-size: 0.14rem;
  font-family: "SourceHanSansCN-Regular";
  font-weight: 400;
}

@media screen and (max-width: 576px) {
  .link-container {
    width: 100%;
    padding: 0 auto;
  }

  .pl7 {
    padding-left: 0;
    text-align: center;
  }

  .copyright .info {
    width: 85%;
    font-size: 0.18rem;
  }

  .qrcode {
    max-width: 2.12rem;
    margin-bottom: 0.2rem;
  }

  .qr-p {
    left: 0;
    text-align: center;
  }
}
</style>